// 线上明细
import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Button,
  Space,
  message,
  PageHeader
 } from 'antd';
import { withRouter } from "react-router-dom";
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index' 

const { Option } = Select;
 const FormItem = Form.Item;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }
 const wrapStyle = {
    // display: 'flex',
    // justifyContent: 'space-between',
    // width: '1500px'
 }
function CommissionDetail2(props) {
    let path = 'company:BillUpload'
    const [form] = Form.useForm();
    const {parmObj} = props
    const initSearch = {
        keyword:'',
    }
    const [tableData,setTableData] = useState([])
    const [resultObj,setResultObj] = React.useState({});
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
     
    let columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align:'center',
            
        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align:'center',
            
        }, {
            title: '落地方案',
            dataIndex: 'planTypeName',
            key: 'planTypeName',
            width: 150,
            align:'center',
            
        },{
            title: '检查结果',
            dataIndex: 'checkResultName',
            key: 'checkResultName',
            width: 150,
            align:'center',
            
        },  {
            title: '佣金（元)',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            width: 150,
            align:'center',
            
        }, {
            title: '打款备注',
            dataIndex: 'content',
            key: 'content',
            width: 150,
            align:'center',
            
        }, {
            title: '服务费（元）',
            dataIndex: 'serviceAmount',
            key: 'serviceAmount',
            width: 150,
            align:'center',
            
        }, {
            title: '税费（元）',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: 150,
            align:'center',
        },
     
    ];
   
    if(parmObj.lineType != 1){
        columns.push({
            title: '收款卡号',
            dataIndex: 'bankNumber',
            key: 'bankNumber',
            width: 150,
            align:'center',
            
        },{
            title: '开户行',
            dataIndex: 'bankName',
            key: 'bankName',
            width: 150,
            align:'center',
            
        })
    }
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <div>
                    <Form form={form} >
                        <Row>
                            <FormItem label="检查结果" name="checkResult"  style={itemStyle} {...labelStyleObj}>
                                <Select>
                                    <Option value={'JRSH'}>进入人工审核</Option>
                                    <Option value={'TG'}>检查通过</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                                <Input placeholder="姓名丨身份证号" />
                            </FormItem>
                        
                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                                {store.getState().userReducer.permissions.indexOf(`${path}:exportBillDetail`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                            </FormItem>
                        </Row>
                    </Form>
                </div>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        getsearchObj(){
            return (
                {
                    checkResult:form.getFieldsValue().checkResult,
                    keyword:form.getFieldsValue().keyword,
                    billNo:parmObj.billNo,
                }
            )
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/company/billUpload/getBillDetail',{
                ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        async getImportStat(){
            let result = await request.get('/company/billUpload/getBillStat',{
                billNo:parmObj.billNo,
            })
            setResultObj(result)
        },
        handleExport(){
            request.downExport('/company/billUpload/exportBillDetail',methodsFn.getsearchObj())
        }
      
   
    }
    useEffect(() => {
        methodsFn.getImportStat()
    },[])
    useEffect(() => {
        var obj = {
            checkResult:form.getFieldsValue().checkResult,
            keyword:form.getFieldsValue().keyword,
            billNo:parmObj.billNo,
        }
        methodsFn.getTableList(obj)
    
    },[pageNum])

    return (
        <div>
            <PageHeader  className="site-page-header" title="账单明细" onBack={props.callFn} subTitle="">
                    <div style={{margin:'5px 0'}}>
                        <Space size="large">
                            <span>佣金账单ID：{resultObj.billNo}</span>
                            <span>账单月份：{resultObj.month}</span>
                            <span>发放方式：{resultObj.lineTypeName}</span>
                            <span>财税公司：{resultObj.taxEnterpriseName}</span>
                        </Space>
                    </div>
                    <div style={{margin:'5px 0'}}>
                        <Space size="large">
                            <span>发放人数：{resultObj.rowCount}人</span>
                            <span>个人佣金：￥{resultObj.commissionAmountCount}</span>
                            <span>平台服务费：￥{resultObj.serviceAmountCount}</span>
                            <span>平台税费：￥{resultObj.taxAmountCount}</span>
                            <span>合计放款：￥{resultObj.totalAmountCount}</span>
                        </Space>
                       
                    </div>
            </PageHeader>
           
            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content',y: 500 }}
            columns={columns} 
            dataSource={tableData} 
            rowKey={(record) => record.idNumber}
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />
      

         
        </div>
    )
}

export default withRouter(CommissionDetail2)
