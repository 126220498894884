import React, { useState, useEffect, useRef } from 'react'
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Table,
  Space,
  Spin,
  Typography
} from 'antd'
import {request} from '../../../../utils/fetch'

import ViewModel from './ViewModel'

const { Link } = Typography
const labelStyleObj = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
}
const itemStyle = { width: '364px', marginRight: '15px' }

const DocumentSave = (props) => {
  let path = 'platform:StaffKeep'
  const [form] = Form.useForm()
  const [tableData, setTableData] = useState([{keepNo:1}])
  const [loading, setLoading] = useState(false) // 页面loading
  const initialFormValues = {
    keyword: '', // 关键字
  }
  const ViewModelRef = useRef()
  const pageSize = 20
  // 分页
  const [pagination, setPagination] = useState({
    pageNum: 1,
    pageSize: 20,
    total: 0
  })
  // 列表字段
  const tableColumns = [
   {
      title: '姓名',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      width: 100
    }, {
      title: '身份证号',
      dataIndex: 'idNumber',
      key: 'idNumber',
      align: 'center',
      width: 180
    }, {
      title: '办证状态',
      dataIndex: 'keepStatusName',
      key: 'keepStatusName',
      align: 'center',
      width: 120
    }, {
      title: '身份证正反面',
      key: 'keepStatusName',
      align: 'center',
      width: 100,
      render: (record) => {
        return (
          <Space>
            <Link onClick={() => onView(record)}>查看</Link>
          </Space>
        )
      }
    },
    {
      title: '电子协议',
      key: 'keepStatusName',
      align: 'center',
      width: 100,
      render: (record) => {
        return (
          <Space>
            <Link onClick={() => onViewPdf(record)}>查看协议</Link>
          </Space>
        )
      }
    }
  ]
  const onViewPdf = (record) => {
    window.open(record.pdfViewUrl)
  }
  const onView = (record) => {
    ViewModelRef.current.showModal(record)
  }

  // 获取列表数据
  const getTableLists = async (page) => {
    const data = {
      keyword: form.getFieldsValue().keyword,
      pageNum: page.pageNum,
      pageSize: page.pageSize
    }
    try {
      setLoading(true)
      let result = await request.get('/company/staffKeep/list',data)
      setPagination(prev => {
        return {
          ...prev,
          total: result.totalCount
        }
      })
      setTableData(result.list)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log(err)
    }
  }
  useEffect(() => {
    getTableLists(pagination)
  }, [])
  const submit = () => {
    getTableLists({ pageNum: 1, pageSize: pageSize })
    setPagination(prev => {
      return {
        ...prev,
        pageNum: 1
      }
    })
  }
  // 重置
  const resetForm = () => {
    // console.log(form.getFieldsValue().date)
    form.resetFields()
  }
  //分页改变
  const tablePageChange = (page, pageSize) => {
    getTableLists({ pageNum: page, pageSize: pageSize })
    setPagination(prev => {
      return {
        ...prev,
        pageNum: page
      }
    })
  }
  return (
    <Spin spinning={loading}>
      <Form labelCol={{ span: 6 }} initialValues={initialFormValues} form={form} {...labelStyleObj}>
        <Row>
          <Form.Item style={itemStyle} name="keyword" label="关键字">
            <Input placeholder="姓名丨身份证号" />
          </Form.Item>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Space>
              <Button type="primary" onClick={submit}>查询</Button>
              <Button onClick={resetForm}>重置</Button>
            </Space>
          </Col>
        </Row>
      </Form>
      <Table
        columns={tableColumns}
        dataSource={tableData}
        scroll={{ x: 1600, y: 500 }}
        rowKey={record => record.keepNo}
        pagination={{
          showSizeChanger: false,
          position: ['bottomCenter'],
          total: pagination.total,
          current: pagination.pageNum,
          pageSize: pagination.pageSize,
          showTotal: (total) => `共 ${total} 条数据`,
          onChange: (page, pageSize) => tablePageChange(page, pageSize)
        }}
      />
      <ViewModel ref={ViewModelRef} />
    </Spin>
  )
}

export default DocumentSave