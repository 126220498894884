import React,{useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Radio,
  Button,
  DatePicker,
  Modal,
  Typography,
  Spin
 } from 'antd';
 import { LoadingOutlined } from '@ant-design/icons';
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index' 
import moment from 'moment';
const { Paragraph } = Typography;
const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;
const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 20,
      }}
      spin
    />
  );
function CapitalAccount(props) {
    let path = 'company:CompanyBase'
    // company:CompanyBase:exportAccountsCapitalDetail
    const [form] = Form.useForm();
    const initSearch = {
        date:[],
    }
    const [dayValue, setDayValue] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [tableData,setTableData] = useState([])
    const [visible, setVisible] = React.useState(false);
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [taxEnterpriseCode, settaxEnterpriseCode] = useState('')
    const [pageNum,setPageNum] = useState(1)
    const [totalMoney,setTotalMoney] = useState()       //开票金额
    const [companyAccounts,setCompanyAccounts] = useState({})     
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const [spinLoading, setSpinLoading] = useState(false);
    
    const columns = [
        {
            title: '进出账时间',
            dataIndex: 'dealTime',
            key: 'dealTime',
            width: 150,
            align:'center',
        },
         {
            title: '收支类型',
            dataIndex: 'dealTypeName',
            key: 'dealTypeName',
            width: 150,
            align:'center',
        },{
            title: '变动金额',
            dataIndex: 'dealAmount',
            key: 'dealAmount',
            width: 180,
            align:'center',
        },
    ];
   
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="支付完成时间" name="date" style={{marginLeft:'15px'}}>
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <Form.Item name='dealType' style={{ width: '360px',float: 'left' }} labelCol={{ flex: '100px' }} label='类型'>
                        <Select allowClear placeholder='全部'>
                            <Option value='4'>转出</Option>
                            <Option value='5'>充值</Option>
                            <Option value='3'>佣金发放</Option>
                            <Option value='2'>转到税费</Option>
                            <Option value='1'>转到服务费</Option>
                            <Option value='7'>税费退回</Option>
                            <Option value='6'>服务费退回</Option>
                        </Select>
                    </Form.Item>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:exportAccountsCapitalDetail`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
                endDate = moment().format('YYYY-MM-DD');
            
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginTime: beginTime,
                endTime: endTime,
                dealType: formObj.dealType,
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setMonth('')
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj,code){
            if(!code){
                code = taxEnterpriseCode
            }
            let result = await request.get('/company/companyBase/getAccountsCapitalDetail',{
               ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
                taxEnterpriseCode: code
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        renewalDeal(){
            setSpinLoading(true)
            request.get('/company/companyBase/renewalDeal',{
                taxEnterpriseCode: taxEnterpriseCode,
                companyCode: localStorage.getItem("company"),
            }).then(res=>{
                methodsFn.getAccountsInfo()
            }).catch(()=>{
                setSpinLoading(false)
            })
        },
        async getAccountsInfo(code){
            setSpinLoading(true)
            if(!code){
                code = taxEnterpriseCode
            }
            let result = await request.get('/company/companyBase/getCompanyBalances',{
                taxEnterpriseCode: code
            })
            setSpinLoading(false)
            setTotalMoney(result)
        },
        async getCompanyAccounts(){
            setVisible(true)
            let result = await request.get('/company/companyBase/getCompanyAccount',{taxEnterpriseCode:taxEnterpriseCode})
            setCompanyAccounts(result)
        },
        selChange(value){
            settaxEnterpriseCode(value)
            methodsFn.getAccountsInfo(value)
            methodsFn.getTableList(methodsFn.getsearchObj(),value)
          },
        handleExport(){
            let exportObj = methodsFn.getsearchObj()
            request.downExport('/company/companyBase/exportAccountsCapitalDetail',
            {...exportObj,
            pageSize:pageSize,
            pageNum:pageNum,
            taxEnterpriseCode: taxEnterpriseCode})
        },
        // 获取所有财税企业
        async getTaxEnterpriseListall(company){
            const res = await request.get('/common/getCompletedTaxEnterpriseList',{companyCode:company})
            setTaxEnterpriseLis(res)
            if(res&&res.length>0){
                settaxEnterpriseCode(res[0].code)
                methodsFn.getTableList(methodsFn.getsearchObj(),res[0].code)
                methodsFn.getAccountsInfo(res[0].code)
            }
        }
    }   
    
    useEffect(() => {
        methodsFn.getTaxEnterpriseListall(localStorage.getItem("company"))
    },[])
    useEffect(() => {
        
    
    },[pageNum])

    return (
        <div>
            <div style={{
                'background': '#fff',
                'marginBottom': '10px',
                'padding': '10px',
            }}>
                <div className="selebox">
                    <Select style={{width:'300px'}} onChange={methodsFn.selChange} value={taxEnterpriseCode} allowClear>
                        {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                    </Select>
                </div>
                <h2>账户余额</h2>
                <div style={{'paddingLeft': '10px', display: 'flex',alignItems: 'center'}}>
                    <div style={{'fontSize': '28px',display: 'flex',alignItems: 'center'}}>
                        <div>
                        ￥
                        </div> 
                        <Spin spinning={spinLoading} indicator={antIcon} >
                        {totalMoney}
                        </Spin>
                        
                    </div> 
                    <Button type="primary" style={{'margin': '0 10px'}} onClick={() => methodsFn.getCompanyAccounts()}>充值</Button>
                    <div>充值未到账？<Button type="link" onClick={() => methodsFn.renewalDeal()}>点击刷新</Button></div>
                </div>
            </div>
            {myComponent.renderSearchForm()}
            <Table 
                scroll={{ x: 'max-content',y:500}}
                columns={columns} 
                rowKey={(record) => record.id}
                dataSource={tableData} 
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger:false,
                    showTotal:(total) =>  `共 ${total} 条数据` ,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />

            <Modal
                title='汇款信息 '
                visible={visible}
                footer={false}
                onCancel={() => setVisible(false) }>
                <div>
                    <div className="flex">
                        <span>账户名称：</span>
                        <Paragraph
                          copyable={{
                            tooltips: ['点击复制', '复制成功'],
                          }}
                        >{companyAccounts.companyName}</Paragraph>
                    </div>
                    <div className="flex">
                        <span>开户银行：</span>
                        <Paragraph 
                          copyable={{
                            tooltips: ['点击复制', '复制成功'],
                          }}
                        >农业银行</Paragraph>
                    </div>
                    <div className="flex">
                        <span>专属账户：</span>
                        <Paragraph
                          copyable={{
                            tooltips: ['点击复制', '复制成功'],
                          }}
                        >{companyAccounts.ownAccNo}</Paragraph>
                    </div>
                </div>
     
            </Modal>
        </div>
    )
}

export default CapitalAccount
