
import Login from './pages/Login';
import NotFind from './pages/NotFind';

import Home from './pages/admin/home/Home';
import AccountManage from './pages/admin/basicInfor/AccountManage/AccountManage';
import RolePermission from './pages/admin/basicInfor/RolePermission/RolePermission';
import PersonList from './pages/admin/basicInfor/PersonList/PersonList';
import documentSave from './pages/admin/basicInfor/documentSave/index';
import ContractInfo from './pages/admin/basicInfor/ContractInfo/ContractInfo';

import InvoiceApply from './pages/admin/invoiceManage/InvoiceApply/InvoiceApply'
import InvoiceRecord from './pages/admin/invoiceManage/InvoiceRecord/InvoiceRecord'
import SettleQuery from './pages/admin/commissionManage/SettleQuery/SettleQuery'
import OnlinePay from './pages/admin/commissionManage/OnlinePay/OnlinePay'
import CommissionUpload from './pages/admin/commissionManage/CommissionUpload/CommissionUpload'
import CheckQuery from './pages/admin/commissionManage/CheckQuery/CheckQuery'
import CapitalAccount from './pages/admin/commissionManage/CapitalAccount/CapitalAccount'
import bankRight from './pages/admin/commissionManage/bankRight/newBankRight'
import CompanyInfoPage from './pages/admin/companyInfo'
import HistoryConfig from './pages/admin/companyInfo/HistoryConfig'
import BankReceipt from './pages/admin/commissionManage/BankReceipt/BankReceipt'



//不需要权限
export const mainRouter = [
    { path: "/login", name: "Login", component: Login },
    { path: "/404", name: "NotFind", component: NotFind },    
]

//需要登录
export const adminRouter = [
    { path: "/admin/home", name: "首页", component: Home },
    { path: '/admin/personList', name: '个人列表', component: PersonList},
    { path: '/admin/documentSave', name: '证件留档', component: documentSave},
    { path: '/admin/accountManage', name: '账号管理', component: AccountManage},
    { path: '/admin/rolePermission', name: '角色权限', component: RolePermission},
    { path: '/admin/contractInfo', name: '发包信息', component: ContractInfo},
    { path: '/admin/invoiceApply', name: '开票申请', component: InvoiceApply},
    { path: '/admin/invoiceRecord', name: '开票记录', component: InvoiceRecord},
    { path: '/admin/settleQuery', name: '结算查询', component: SettleQuery},
    { path: '/admin/BankReceipt', name: '银行回单', component: BankReceipt},
    { path: '/admin/onlinePay', name: '线上支付', component: OnlinePay},
    { path: '/admin/commissionUpload', name: '佣金上传', component: CommissionUpload},
    { path: '/admin/checkQuery', name: '审核查询', component: CheckQuery},
    { path: '/admin/capitalAccount', name: '资金账户', component: CapitalAccount},
    { path: '/admin/bankRight', name: '银行确权', component: bankRight},
    { path: '/admin/companyInfo', name: '企业信息', component: CompanyInfoPage},
    { path: '/admin/historyConfig/:id', name: '历史配置', component: HistoryConfig}
    

]

