import React, { useRef, useState, useEffect } from 'react'
import {
    Row,
    Col,
    Table,
    Select,
    Form,
    Input,
    Radio,
    Button,
    DatePicker,
    Popconfirm,
    Tag,
    Space
} from 'antd';
import qs from 'qs'
import moment from 'moment';
import SettleQueryDetail1 from './SettleQueryDetail1'
import SettleQueryDetail2 from './SettleQueryDetail2'
import store from '../../../../store/index'
import { request } from '../../../../utils/fetch'

const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;
const itemStyle = {
    width: '320px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 }
}

function SettleQuery(props) {
    let path = "company:Settlement"
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''));
    const [form] = Form.useForm();
    const initSearch = {
        date: [],
        companyCode: ''
    }
    const [dayValue, setDayValue] = React.useState('');
    const [tableData, setTableData] = useState([])
    const [month, setMonth] = React.useState('');
    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [isDetail, setIsDetail] = useState(false)
    const [parmObj, setParmObj] = useState({})       //传给明细的参数
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const columns = [
        {
            title: '时间',
            dataIndex: 'statusUpdateTime',
            key: 'statusUpdateTime',
            width: 150,
            align: 'center',
        },
        {
            title: '佣金状态',
            dataIndex: 'paymentStatusName',
            key: 'paymentStatusName',
            width: 150,
            align: 'center',

        }, {
            title: '服务费状态',
            dataIndex: 'servicePaymentStatusName',
            key: 'servicePaymentStatusName',
            width: 150,
            align: 'center',
        },{
            title: '税费状态',
            dataIndex: 'taxPaymentStatusName',
            key: 'taxPaymentStatusName',
            width: 150,
            align: 'center',
        }, {
            title: '发放方式',
            dataIndex: 'lineTypeName',
            key: 'lineTypeName',
            width: 150,
            align: 'center',

        },
        {
            title: '申请放款合计（元）',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: 180,
            align: 'center',
        },
        {
            title: '账单月份',
            dataIndex: 'month',
            key: 'month',
            width: 180,
            align: 'center',
        },  {
            title: '佣金账单ID',
            dataIndex: 'billNo',
            key: 'billNo',
            width: 180,
            align: 'center',

        },
        {
            title: '实际放款合计（元）',
            dataIndex: 'sureTotalAmount',
            key: 'sureTotalAmount',
            width: 180,
            align: 'center',

        }, {
            title: '实际个人佣金（元）',
            dataIndex: 'sureCommissionAmount',
            key: 'sureCommissionAmount',
            width: 180,
            align: 'center',

        }, {
            title: '实际平台服务费（元）',
            dataIndex: 'sureServiceAmount',
            key: 'sureServiceAmount',
            width: 180,
            align: 'center',

        }, {
            title: '实际平台税费（元）',
            dataIndex: 'sureTaxAmount',
            key: 'sureTaxAmount',
            width: 180,
            align: 'center',
        },
        {
            title: '操作',
            key: 'action',
            width: 260,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">
                    {/* <a onClick={() =>  window.location.href = record.fileUrl }>下载源文件</a> */}
                    {/* <a onClick={() =>  window.location.href = record.fileUrl }>下载错误文件（没有字段）</a> */}
                    {store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 && <a onClick={() => { methodsFn.goDetail(record) }}>明细</a>}
                </Space>
            ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label="时间" name="date" style={{ marginLeft: '40px' }} >
                            <RangePicker
                                onChange={methodsFn.timeChange}
                            />
                        </FormItem>
                        <FormItem style={{ with: '600px', marginLeft: '26px' }} label="">
                            <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                                <Radio value={1}>今天</Radio>
                                <Radio value={7}>近7天</Radio>
                                <Radio value={30}>近30天</Radio>
                            </Radio.Group>
                        </FormItem>
                    </Row>
                    <Row>
                        {/* <FormItem name='taxEnterpriseCode' label="财税企业" style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder="全部">
                            {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                            </Select>
                        </FormItem> */}
                        <FormItem label="佣金状态" name="paymentStatus" style={itemStyle} {...labelStyleObj}>
                            <Select placeholder="全部">
                                <Option value={'PTQR'}>平台已确认</Option>
                                <Option value={'XS_WZF'}>待企业确认</Option>
                                <Option value={'XX_WZF'}>待平台确认</Option>
                                <Option value={'WZF'}>未支付</Option>
                                <Option value={'ZFZ'}>支付中</Option>
                                <Option value={'ZFSB'}>支付失败</Option>
                                <Option value={'WC_QBZF'}>支付完成且全部成功</Option>
                                <Option value={'WC_CW'}>支付完成,含错误数据</Option>
                                <Option value={'FQ_QY'}>企业放弃支付</Option>
                                <Option value={'FQ_PT'}>平台放弃支付</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="账单月份" name="month" style={itemStyle} {...labelStyleObj}>
                            <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
                        </FormItem>
                        <FormItem label="发放方式" name="lineType" style={itemStyle} {...labelStyleObj}>
                            <Select placeholder="全部">
                                <Option value={1}>线上发放</Option>
                                <Option value={2}>线下发放</Option>
                            </Select>
                        </FormItem>
                    </Row>
                    <Row>
                        <Col span={24} md={24} lg={24}>
                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                                {store.getState().userReducer.permissions.indexOf(`${path}:export`) > -1 && <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn = {
        // 获取所有财税企业
        async getTaxEnterpriseListall(company){
            const res = await request.get('/common/getTaxEnterpriseList',{})
            setTaxEnterpriseLis(res)
        },
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1, 'd').format('YYYY-MM-DD'),
                endDate = moment().format('YYYY-MM-DD');

            setDayValue(e.target.value)
            form.setFieldsValue({
                date: [moment(startDate), moment(endDate)]
            })
        },
        timeChange(dates, dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate), moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date: dateArr
            })

        },

        getsearchObj() {
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if (formObj.date.length > 1) {
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            } else {
                beginTime = ''
                endTime = ''
            }
            let obj = {
                beginTime: beginTime,
                endTime: endTime,
                month: month,
                source: formObj.source,
                paymentStatus: formObj.paymentStatus,
                taxEnterpriseCode: formObj.taxEnterpriseCode,
                lineType: formObj.lineType,
            }
            return obj
        },
        handleSearch() {
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset() {
            setMonth('')
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj) {
            let result = await request.get('/company/settlement/getList', {
                ...searchObj,
                pageSize: pageSize,
                pageNum: pageNum,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },

        goDetail(record) {
            setIsDetail(true)
            props.history.push('/admin/settleQuery?detail=' + record.settlementNo + '&lineType=' + record.lineType)
            setParmObj({
                settlementNo: record.settlementNo,
                lineType: record.lineType,
                servicePaymentTime: record.servicePaymentTime,
                paymentTime1: record.paymentTime1,
                paymentTime2: record.paymentTime2,
            })
        },
        detailFn() {
            setIsDetail(false)
            props.history.push('/admin/settleQuery')
        },
        handleExport() {
            request.downExport('/company/settlement/export', methodsFn.getsearchObj())
        }
    }

    useEffect(() => {
        if (props.location.search.indexOf('detail') > -1) {       //解决明细页面刷新问题
            methodsFn.goDetail({
                settlementNo: searchParam.detail,
                lineType: searchParam.lineType,
            })
        }
        methodsFn.getTaxEnterpriseListall(localStorage.getItem("company"))
    }, [])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    }, [pageNum])

    return (
        <div>
            {
                !isDetail ?
                    <div>
                        {myComponent.renderSearchForm()}
                        <Table
                            scroll={{ x: 'max-content', y: 500 }}
                            columns={columns}
                            dataSource={tableData}
                            rowKey={(record) => record.settlementNo}
                            pagination={{
                                position: ['bottomCenter'],
                                total: total,
                                current: pageNum,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                showTotal: (total) => `共 ${total} 条数据`,
                                onChange: (pageNum, pageSize) => setPageNum(pageNum)
                            }}
                        />
                    </div>
                    : parmObj.lineType == 1
                        ? <SettleQueryDetail1 isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn} />
                        : <SettleQueryDetail2 isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn} />
            }

        </div>
    )
}

export default SettleQuery
