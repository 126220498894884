import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  message,
  DatePicker,
  Popconfirm,
  Modal,
  Space
 } from 'antd';
 import qs from 'qs'
import {request} from '../../../../utils/fetch'
import moment from 'moment';
import InvoiceApplyDetail from './InvoiceApplyDetail'
import store from '../../../../store/index' 

const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;
const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }
const formItemLayout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 14,
    },
};
function InvoiceApply(props) {
    let path = 'company:CompanyTicket'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const initSearch = {
        date:[],
    }
    const [dayValue, setDayValue] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [tableData,setTableData] = useState([])
    const [visible, setVisible] = React.useState(false);
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [isDetail,setIsDetail] = useState(false)
    const [parmObj,setParmObj] = useState({})       //传给明细的参数
    const [rows,setRows] = useState([])
    const [totalMoney,setTotalMoney] = useState(0)       //开票金额
    const [receiptList,setReceiptList] = useState([])     
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])

    const columns = [
        {
            title: '支付完成时间',
            dataIndex: 'payTime',
            key: 'payTime',
            width: 150,
            align:'center',
        }, {
            title: '账单月份',
            dataIndex: 'month',
            key: 'month',
            width: 120,
            align:'center',
        },{
            title: '佣金账单ID',
            dataIndex: 'billNo',
            key: 'billNo',
            width: 180,
            align:'center',
        }, 
        // {
        //     title: '财税企业',
        //     dataIndex: 'taxEnterpriseName',
        //     key: 'taxEnterpriseName',
        //     width: 200,
        //     align:'center',
        // },
        {
            title: '发放方式',
            dataIndex: 'lineTypeName',
            key: 'lineTypeName',
            width: 120,
            align:'center',
            // render: (text, record) => record.lineType == 1 ?'线上发放':'线下发放'
        }, {
            title: '来源',
            dataIndex: 'sourceName',
            key: 'sourceName',
            width: 120,
            align:'center',
        },  {
            title: '支付金额',
            dataIndex: 'paymentAmount',
            key: 'paymentAmount',
            width: 150,
            align:'center',
        },  {
            title: '可申请开票金额',
            dataIndex: 'ticketAmount',
            key: 'ticketAmount',
            width: 150,
            align:'center',
        }, 
        {
        title: '操作',
        key: 'action',
        width: 100,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => methodsFn.goDetail(record) }>明细</a>}
            </Space>
        ),
        },
    ];
   
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="支付完成时间" name="date" style={{marginLeft:'15px'}}>
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    <FormItem label="发放方式" name="lineType" style={itemStyle} {...labelStyleObj}>
                        <Select>
                            <Option value={'1'}>线上发放</Option>
                            <Option value={'2'}>线下发放</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="账单月份" name="month"  style={itemStyle} {...labelStyleObj}>
                        <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
                    </FormItem>
                    <FormItem  label="来源" name="source"  style={itemStyle} {...labelStyleObj}>
                        <Select>
                            <Option value={1}>佣金账单</Option>
                            <Option value={2}>审核账单</Option>
                        </Select>
                    </FormItem>
                    {/* <FormItem name='taxEnterpriseCode' label="财税企业" labelCol={{span: 6}} wrapperCol ={{span: 18}} style={{ width:'390px', marginRight:'15px' }}>
                        <Select allowClear placeholder="全部">
                        {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </FormItem> */}
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                        {store.getState().userReducer.permissions.indexOf(`${path}:export`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>}
                        {store.getState().userReducer.permissions.indexOf(`${path}Apply:apply`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleApply}>申请</Button>}
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
                endDate = moment().format('YYYY-MM-DD');
            
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            let beginTime = '',
                endTime = '';
            if(formObj.date.length > 1){
                beginTime = formObj.date[0].format('YYYY-MM-DD')
                endTime = formObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let obj = {
                payBeginDate:beginTime,
                payEndDate:endTime,
                month:month,
                lineType:formObj.lineType,
                taxEnterpriseCode:formObj.taxEnterpriseCode,
                source:formObj.source,
            }
            return obj
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            setMonth('')
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
           
            let result = await request.get('/company/companyTicket/getList',{
               ...searchObj,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        getReceiptList(){
            request.get('/company/companyBase/receiptList').then(res => setReceiptList(res))
        },
        tableChange(key,row){
            setRows(row)
        },
        handleApply(){   
            
            if(rows.length < 1){
                message.error('请先勾选列表')
                return
            }
            let index = rows.findIndex(e=>e.taxEnterpriseCode!=rows[0].taxEnterpriseCode)
            if(index>-1){
                message.error('不能选择不同财税企业的待开票记录')
                return
            }
            let result =  rows.reduce((pre,cur) => {
                return pre + cur.ticketAmount
            },0)
            setTotalMoney(result.toFixed(2))
            setVisible(true)
        },
        handleInvoice(){
            setLoading(true);
            let obj = form2.getFieldsValue()
            let ticketNoList = rows.map(item => item.ticketNo)
            let result = request.post('/company/companyTicketApply/apply',{
                ticketAmount:totalMoney,
                receiptNo:obj.receiptNo,
                ticketNoList:ticketNoList.join(','),
            }).then(res => {
                setLoading(false);
                setVisible(false)
                methodsFn.refresh()
            }).catch(err => setLoading(false))
        },
    
        refresh(){
            methodsFn.handleSearch()
        },
        goDetail(record){
            setIsDetail(true)
            props.history.push('/admin/invoiceApply?settlementNo='+record.settlementNo+'&ticketNo='+record.ticketNo+'&ticketApplyNo='+record.ticketApplyNo)
            setParmObj({
                settlementNo:record.settlementNo,
                ticketNo:record.ticketNo,
                ticketApplyNo:record.ticketApplyNo,
                
            })
        },
        detailFn(){
            setIsDetail(false)
            props.history.push('/admin/invoiceApply')
        },
        handleExport(){
            request.downExport('/company/companyTicket/export',methodsFn.getsearchObj())
        },
         // 获取所有财税企业
        async getTaxEnterpriseListall(company){
            const res = await request.get('/common/getTaxEnterpriseList',{})
            setTaxEnterpriseLis(res)
        }

    }   
    useEffect(() => {
        methodsFn.getReceiptList()
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                settlementNo:searchParam.settlementNo,
                ticketNo:searchParam.ticketNo,
                ticketApplyNo:searchParam.ticketApplyNo,
            })
        }
        methodsFn.getTaxEnterpriseListall(localStorage.getItem("company"))
    },[])
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
    
    },[pageNum])

    return (
        <div>
                {
                    !isDetail?
                    <div>
                    {myComponent.renderSearchForm()}
                    <Table 
                        rowSelection={{
                            type: "checkbox",
                            onChange:methodsFn.tableChange,
                        }}
                        scroll={{ x: 'max-content',y:500}}
                        columns={columns} 
                        rowKey={(record) => record.ticketNo}
                        dataSource={tableData} 
                        pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                    />
                 </div>
                 : <InvoiceApplyDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn}/>
                 }

            <Modal
                title='申请开票'
                visible={visible}
                confirmLoading={loading}
                onOk={methodsFn.handleInvoice}
                onCancel={() => setVisible(false) }
            >
                <Form
                    form={form2}
                    {...formItemLayout}
                    initialValues={{
                        planType:''
                    }}
                    >
                    <FormItem
                      label="开票金额"
                      name="ticketAmount">
                        <Input disabled={true} placeholder={totalMoney} />
                    </FormItem>
                    <FormItem
                      rules={[{ required: true, message: '请选择' }]}
                      label="收件信息"
                      name="receiptNo">
                        <Select placeholder="">
                        {
                            receiptList.map(item => {
                                return <Option value={item.receiptNo} key={item.receiptNo}>{item.receiptName+'/'+item.receiptPhone+'/'+item.receiptAddress}</Option>
                            })
                        }
                           
                        </Select>
                    </FormItem>
                </Form>
            </Modal>
        </div>
    )
}

export default InvoiceApply
