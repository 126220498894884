import React,{useState,useEffect,useImperativeHandle} from 'react'
import { 
  Modal,
  Form,
  Typography,
  message,
} from 'antd';
import {request} from '../../../../utils/fetch'
const { Paragraph } = Typography;

const MyModel1 = (props) => {
  const [form] = Form.useForm();
  const {cRef} = props
  const [visible, setVisible] = React.useState(false);
  const [payValue, setPayValue] = React.useState(1);
  const [tableData,setTableData] = useState([])
  const [total,setTotal] = useState(0)
  const [pageNum,setPageNum] = useState(1)
  const [rows,setRows] = useState([])
  const [totalMoney,setTotalMoney] = useState(0)       //开票金额
  const [loading, setLoading] = React.useState(false);
  const [companyAccounts,setCompanyAccounts] = useState({})     

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(cRef, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (val) => {
      setPayValue(1)
      setVisible(true)
      methodsFn.getTableList(Object.assign({},form.getFieldsValue(),val))
      methodsFn.getCompanyAccounts({taxEnterpriseCode: val.taxEnterpriseCode})
      form.resetFields()
    },
   
  }));
  const methodsFn =  {
    payChange(e) {
      setPayValue(e.target.value)
      setRows([])
    },
    handleSearch() {
      setPageNum(1)
      methodsFn.getTableList(Object.assign({},form.getFieldsValue(),{settlementNo:props.settlementNo}))
  },
  handleFormReset(){
      form.resetFields();
  },
  async getTableList(searchObj){
      let result = await request.get('/company/onlinePayment/waitPaymentList',{
          ...searchObj,
      })
         setTotal(result.totalCount)
         setTableData(result.list)
  },
  
    tableChange(key,row){
      setRows(row)
    },
    async getCompanyAccounts(val){
        setVisible(true)
        let result = await request.get('/common/getCompanyAccounts',val)
        setCompanyAccounts(result)
    },
    handlePay(){   
      if(rows.length < 1){
          message.error('请先勾选列表')
          return
      }
      if(totalMoney > props.sumMoney){
        message.error('余额不足，重新勾选列表')
        return
      }
      let arr = rows.map(item => {
          return item.settlementDetailNo
      })
      request.post('/company/onlinePayment/payment',{
        settlementNo:props.settlementNo,
        settlementDetailNoList:arr.join()
     }).then(res => {
        message.success(res.msg)
        setVisible(false) 
        props.refresh() 
     })
   },
   handleExport(){
        request.downExport('/company/onlinePayment/exportWaitPayment',{
            settlementNo:props.settlementNo,
            keyword:form.getFieldsValue().keyword
        })
    }
  }
useEffect(() => {
    let result =  rows.reduce((pre,cur) => {
        return pre + (cur.commissionAmount+cur.serviceAmount+cur.taxAmount)
      },0)
      setTotalMoney(result)
 },[rows])
//   useEffect(() => {
//     methodsFn.getTableList(form.getFieldsValue())
//  },[pageNum])

  return (
    <>
        <Modal
                forceRender
                title='余额不足'
                width='800px'
                visible={visible}
                confirmLoading={loading}
                footer={false}
                onCancel={() => setVisible(false) }
                >
                <div>
                    <div>
                        <div>当前账户余额 ￥{props.sumMoney} ， 待支付金额￥{props.waitAmount}</div>
                        <div>余额不足 ，请充值￥{(props.waitAmount - props.sumMoney).toFixed(2)}</div>
                    </div>
                    {/* <div style={{'margin':'20px 0'}}>
                        <h3>选择处理方式:</h3>
                        <div>
                        <Radio.Group onChange={methodsFn.payChange} value={payValue}>
                            <Radio value={1}>需补缴的充值金额汇款指定账户后，再支付</Radio>
                            <Radio value={2}>选择部分人员先支付，剩余人员补缴充值后再支付</Radio>
                        </Radio.Group>
                        </div>
                    </div>
                    {
                      payValue == 1
                      ? null
                      :<div>
                      <Form form={form} initialValues={{
                        keyword:''
                      }}>
                            <FormItem label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                              <Input placeholder="姓名丨身份证号" />
                            </FormItem>
                            <FormItem>
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleExport}>导出</Button>
                            </FormItem>
                      </Form>
                      <Table 
                          scroll={{ x: 'max-content',y:500 }}
                          columns={columns} 
                          rowSelection={{
                            type: "checkbox",
                            onChange:methodsFn.tableChange,
                        }}
                          rowKey={(record) => record.id}
                          dataSource={tableData} 
                          pagination={{
                              position: ['bottomCenter'],
                              total: total,
                              current: pageNum,
                              pageSize: pageSize,
                              showSizeChanger:false,
                              showTotal:(total) =>  `共 ${total} 条数据` ,
                              onChange: (pageNum, pageSize) => setPageNum(pageNum)
                          }}
                        />

                        已勾选{rows.length}人   合计待支付金额  ￥{totalMoney.toFixed(2)}
                        <Button type="primary" onClick={methodsFn.handlePay}>去支付</Button>
                        
                    </div>
                    } */}

                    <div>
                      <div className="flex">
                          <span>账户名称：</span>
                          <Paragraph
                          copyable={{
                              tooltips: ['点击复制', '复制成功'],
                          }}
                          >{companyAccounts.accountName}</Paragraph>
                      </div>
                      <div className="flex">
                          <span>开户银行：</span>
                          <Paragraph 
                          copyable={{
                              tooltips: ['点击复制', '复制成功'],
                          }}
                          >{companyAccounts.openBankName}</Paragraph>
                      </div>
                      <div className="flex">
                          <span>专属账户：</span>
                          <Paragraph
                          copyable={{
                              tooltips: ['点击复制', '复制成功'],
                          }}
                          >{companyAccounts.accountId}</Paragraph>
                      </div>
                      <div className="flex">
                          <span>备注信息：</span>
                          <Paragraph
                          copyable={{
                              tooltips: ['点击复制', '复制成功'],
                          }}
                          >服务费</Paragraph>
                      </div>
                      <ul style={{'color': '#a7a7a7'}}>
                          <li>1、请务必使用{companyAccounts.companyName}企业绑定的对公账户<b style={{'color':'#1890ff'}}>{companyAccounts.baseAccountId}</b>进行转账，否则将导致充值失败！</li>
                          <li>2、汇款请务必备注<b style={{'color':'#1890ff'}}>”服务费“</b>，否则将会被退款！</li>
                          <li>3、汇款时间在工作日9：00-16：30之间预计2小时内到账，其他时间汇款预计下个工作日内到账。</li>
                      </ul>
                    </div>
                </div>
        </Modal>
    </>
  );
};
export default MyModel1



