import { useRef, useEffect, useCallback } from 'react'

export const setToken = function(token) {
   localStorage.setItem('token',token)
}
export const setMobile = function(mobile) {
    localStorage.setItem('mobile',mobile)
 }
 export const setUsername = function(username) {
    localStorage.setItem('username',username)
 }
export const setMenu = function(list) {
    localStorage.setItem('menu',list)
 }

export const setPermissions = function(list) {
   localStorage.setItem('permissions',list)
}

export const getToken = function() {
    localStorage.getItem('token')
 
 }
 export const clearToken = function() {
    localStorage.removeItem('token')
 }
export const isLogin = function() {
    if(localStorage.getItem('token')) return true
    return false

}

export function setLocalStorage(name, data) {
   const dataStr = JSON.stringify(data)
   return localStorage.setItem(name, dataStr)
}

export function getLocalStorage(name) {
   return localStorage.getItem(name)
}

// 不传参，删除所有
export function removeLocalStorage(name) {
   name === 'undefined'
      ? localStorage.clear()
      : localStorage.removeItem(name)
}


// 防抖hook
export function useDebounce(fn, delay, dep = []) {
   const { current } = useRef({ fn, timer: null });
   useEffect(function () {
     current.fn = fn;
   }, [fn]);
 
   return useCallback(function f(...args) {
     if (current.timer) {
       clearTimeout(current.timer);
     }
     current.timer = setTimeout(() => {
       current.fn(...args);
     }, delay);
   }, dep)
 }


//  节流
export function useThrottle(fn, delay, dep = []) {
   const { current } = useRef({ fn, timer: null });
   useEffect(function () {
     current.fn = fn;
   }, [fn]);
 
   return useCallback(function f(...args) {
     if (!current.timer) {
       current.timer = setTimeout(() => {
         delete current.timer;
       }, delay);
       current.fn(...args);
     }
   }, dep);
 }
