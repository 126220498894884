import React,{useState,useEffect,useRef} from 'react'
import {
  Row,
  Table,
  Select,
  Form,
  Input,
  Button,
  Col,
  Space,
  Popconfirm,
  PageHeader,
  message,
 } from 'antd';
import qs from 'qs'
import {request} from '../../../../utils/fetch'
import store from '../../../../store/index'
import MyModel1 from './MyModel1';
const { Option } = Select;
 const FormItem = Form.Item;
 const itemStyle = {
     width:'300px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 9},
    wrapperCol:{ span: 15}
 }

function AccountManage(props) {
    let path = 'company:User'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const myModel1 = useRef();
    const initSearch = {
        userName:[],
        keyword:'',
        status:''
    }
    const [tableData,setTableData] = useState([])
    const [roleList,setRoleList] = useState([])
    const [roleList1,setRoleList1] = useState([])
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [titleModel,setTitleModel] = useState('新增')
     
    const columns = [
        {
            title: '登录手机号',
            dataIndex: 'phone',
            key: 'phone',
            align:'center',
            width:120,
            
        }, {
            title: '姓名',
            dataIndex: 'userName',
            key: 'userName',
            align:'center',
            width:120,
            
        }, {
            title: '角色名称',
            dataIndex: 'roleNames',
            key: 'roleNames',
            align:'center',
            width:120,
            
        },  {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            align:'center',
            width:120,
            render: (text, record) => record.status ?'启用':'停用'
        },{
            title: '操作',
            key: 'action',
            align:'center',
            width:220,
            render: (text, record) => (
                <Space size="middle">
                    {store.getState().userReducer.permissions.indexOf(`${path}:update`)>-1&&<a onClick={() => {methodsFn.handleModify(record)}}>修改</a>}
                    {
                       store.getState().userReducer.permissions.indexOf(`${path}:remove`)>-1&&(!record.status)
                        ? <Popconfirm
                        title="确定删除吗？"
                        onConfirm={() => {
                            methodsFn.handleDel(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <a>删除</a>
                    </Popconfirm>
                    :null
                    }
                 
                    {store.getState().userReducer.permissions.indexOf(`${path}:resetPwd`)>-1&&<Popconfirm
                        title="确定重置登录密码吗？重置后登录密码与登录手机号一致！"
                        onConfirm={() => {
                            methodsFn.handleReset(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <a>重置密码</a>
                    </Popconfirm>}
                    {store.getState().userReducer.permissions.indexOf(`${path}:status`)>-1&&<Popconfirm
                        title={`确定${record.status?'停用':'启用'}吗？`}
                        onConfirm={() => {
                            methodsFn.handleStatus(record)
                        }}
                        okText="确定"
                        cancelText="取消"
                        className="marginLeft"
                        >
                        <a onClick={() => {}}>{record.status?'停用':'启用'}</a>
                    </Popconfirm>}
                </Space>
            ),
            },
     
    ];
   
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label="拥有角色" name="roleIds"  style={itemStyle} {...labelStyleObj}>
                            <Select>
                                {
                                    roleList.map(item => {
                                       return <Option value={item.id} key={item.id}>{item.roleName}</Option>
                                    })
                                }
                            </Select>
                        </FormItem>
                        <FormItem label="用户状态" name="status"  style={itemStyle} {...labelStyleObj}>
                            <Select>
                                <Select.Option value={0}>停用</Select.Option>
                                <Select.Option value={1}>启用</Select.Option>
                            </Select>
                        </FormItem>
                        <FormItem label="关键字" name="keyword"  style={itemStyle} {...labelStyleObj}>
                            <Input placeholder="姓名丨登录手机号" />
                        </FormItem>
                      
                    </Row>
                    <Row>
                        <Col span={24} md={24} lg={24}>
                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                                {store.getState().userReducer.permissions.indexOf(`${path}:add`)>-1&&<Button style={{ marginLeft: 10 }} onClick={methodsFn.handleAdd}>新增</Button>}
                            </FormItem>
                        </Col>
                    </Row>
                   
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(form.getFieldsValue())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getTableList(searchObj){
            let result = await request.get('/company/user/list',Object.assign(searchObj,
                {
                    companyCode:searchParam.companyCode || '',
                    pageSize:pageSize,
                    pageNum:pageNum,
                }
            ))
               setTotal(result.totalCount)
               setTableData(result.list)
        },
        handleAdd(){
            setTitleModel('新增')
            myModel1.current.showModal()
        },
        handleModify(record){
            setTitleModel('修改')
            myModel1.current.reviseModal(record)
        },
        handleStatus(obj){
            let status;
            if(obj.status){
                status = 0
            }else{
                status = 1
            }
            request.post('/company/user/changeStatus',{
                id:obj.id,
                status:status,
            }).then(res => {
                methodsFn.getTableList(form.getFieldsValue())
                message.success('操作成功')
            })
        },
        handleDel(obj){
            request.post('/company/user/remove',{id:obj.id}).then(res => {
                methodsFn.getTableList(form.getFieldsValue())
                message.success(res.msg)
            })
        },
        handleReset(obj){
            request.post('/company/user/resetPwd',{id:obj.id}).then(res => message.success(res.msg))
        },
        refresh(){
            methodsFn.getTableList(form.getFieldsValue())
        },
        async getRoles(){
           let result = await request.get('/common/listRoles',{systemType:2})
           setRoleList(result)
        },
        async getRoles1(){
           let result = await request.get('/common/listRoles',{systemType:2,status:1})
           setRoleList1(result)
        }
       
    }
    useEffect(() => {
        methodsFn.getRoles()
        methodsFn.getRoles1()
    
    },[])
    useEffect(() => {
        methodsFn.getTableList(form.getFieldsValue())
    
    },[pageNum])

    return (
        <div>
             {
               searchParam.detail
                    ?<PageHeader
                    className="site-page-header"
                    onBack={() => {props.history.goBack()}}
                    title="明细"
                    subTitle={'公司名称：'+searchParam.companyName}
                />
                :null
            }

            {myComponent.renderSearchForm()}
           <Table 
            scroll={{ x: 'max-content',y:500 }}
            columns={columns} 
            rowKey={(record) => record.id}
            dataSource={tableData} 
            pagination={{
                position: ['bottomCenter'],
                total: total,
                current: pageNum,
                pageSize: pageSize,
                showSizeChanger:false,
                showTotal:(total) =>  `共 ${total} 条数据` ,
                onChange: (pageNum, pageSize) => setPageNum(pageNum)
            }}
           />
           <MyModel1 cRef={myModel1} roleList={roleList1} titleModel={titleModel} refresh={methodsFn.refresh}/>

         
        </div>
    )
}

export default AccountManage
