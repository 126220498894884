import React,{useImperativeHandle} from 'react'
import { 
  Modal,
  Table,
  Input,
  Space,
  Form,
  message,
  Row,
  Col,
} from 'antd';
import {request} from '../../../../utils/fetch'

const { TextArea } = Input;
const topCol = {
  span:8,
}
const endCol = {
  span:8,
  offset:6
}

const MyModel = (props) => {
  const [form] = Form.useForm();
  const {cRef} = props
  const [visible, setVisible] = React.useState(false);
  

   // 注意useImperativeHandle方法的的第一个参数是目标元素的ref引用
  useImperativeHandle(cRef, () => ({
    // showModal 就是暴露给父组件的方法
    showModal: (val) => {
        setVisible(val);
      }
  }));
  const columns = [
    {
        title: '打款时间',
        dataIndex: 'paymentTime',
        key: 'paymentTime',
    }, {
        title: '企业名称',
        dataIndex: 'companyCodeName',
        key: 'companyCodeName',
    },{
        title: '佣金',
        dataIndex: 'commissionAmount',
        key: 'commissionAmount',
    },
  ];

  return (
    <>
      <Modal
        title='查看'
        width='1000px'
        visible={visible}
        footer={false}
        onCancel={() => setVisible(false)}
      >
    
    <Form form={form}>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">类型:</span>
                <Input placeholder={props.detailObj.ticketTypeName} disabled={true} />
              </div>
            </Col>
           
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">企业名称:</span>
                <Input placeholder={props.detailObj.companyName} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">纳税人类型:</span>
                <Input placeholder={props.detailObj.taxpayerTypeName} disabled={true} />
              </div>
            </Col>
        </Row>

        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">统一社会信用代码:</span>
                <Input placeholder={props.detailObj.taxpayerNumber} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">注册电话:</span>
                <Input placeholder={props.detailObj.registeredPhone} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">注册地址:</span>
                <Input placeholder={props.detailObj.address} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">对公账户:</span>
                <Input placeholder={props.detailObj.bankNumber} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">开户银行:</span>
                <Input placeholder={props.detailObj.bankName} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">收件人:</span>
                <Input placeholder={props.detailObj.receiptName} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">收件电话:</span>
                <Input placeholder={props.detailObj.receiptPhone} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">收件地址:</span>
                <Input placeholder={props.detailObj.receiptAddress} disabled={true} />
              </div>
            </Col>
        </Row>
        {
          props.detailObj.ticketTypeName == '企业申请'
          ? <Row className="margin-15">
          <Col {...topCol}>
            <div className="flex">
              <span className="left-span">申请开票时间:</span>
              <Input placeholder={props.detailObj.createTime} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">申请开票金额:</span>
                <Input placeholder={props.detailObj.ticketAmount} disabled={true} />
              </div>
            </Col>
        </Row>
      :<div>
           <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">确认退费时间:</span>
                <Input placeholder={props.detailObj.backCostTime} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">退费月份:</span>
                <Input placeholder={props.detailObj.backCostMonth} disabled={true} />
              </div>
            </Col>
            <Col {...endCol}>
              <div className="flex">
                <span className="left-span">开票金额:</span>
                <Input placeholder={props.detailObj.ticketAmount} disabled={true} />
              </div>
            </Col>
        </Row>
      </div>
    }
{
  
  props.detailObj.status == 'KPCG'
    ?<div>
       <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">确认开票时间:</span>
                <Input placeholder={props.detailObj.ticketTime} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">操作人:</span>
                <Input placeholder={props.detailObj.ticketUser} disabled={true} />
              </div>
            </Col>
        </Row>
        <Row className="margin-15">
            <Col {...topCol}>
              <div className="flex">
                <span className="left-span">开票备注:</span>
                <Input placeholder={props.detailObj.ticketNote} disabled={true} />
              </div>
            </Col>
          
        </Row>
    </div>
    :null
}
       
        </Form>
      </Modal>
    </>
  );
};
export default MyModel



