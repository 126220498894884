import React, { useRef, useState, useEffect } from 'react'
import {
    Row,
    Col,
    Table,
    Select,
    Form,
    Input,
    Radio,
    Button,
    message,
    DatePicker,
    Upload,
    Popconfirm,
    Modal,
    Space
} from 'antd';
import qs from 'qs'
import axios from 'axios'
import { UploadOutlined, } from '@ant-design/icons';
import { request } from '../../../../utils/fetch'
import store from '../../../../store/index'
import moment from 'moment';
import NextDetail from './NextDetail'
import CommissionDetail2 from './CommissionDetail2'

const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;
const itemStyle = {
    width: '310px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
}
const formItemLayout = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 14,
    },
};
function CommissionUpload(props) {
    let path = 'company:BillUpload'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g, ''));
    const [form] = Form.useForm();
    const [form2] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const initSearch = {
        // stepStatus: '',

    }
    const [dayValue, setDayValue] = React.useState('');
    const [month, setMonth] = React.useState('');
    const [month2, setMonth2] = React.useState('');
    const [tableData, setTableData] = useState([])
    const [visible1, setVisible1] = React.useState(false);
    const [visible2, setVisible2] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)
    const [errList, setErrList] = useState([])
    const [errData, setErrData] = useState({ list: [], totalCount: 0 })
    const [isDetail, setIsDetail] = useState(false)
    const [parmObj, setParmObj] = useState({})       //传给明细的参数    

    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const fileProps = {
        name: 'files',
        fileList: fileList,
        multiple: false,
        accept: '.xls, .xlsx',
        beforeUpload: file => {
            return false;
        },

        onChange(info) {
            let fileCon = [...info.fileList];
            //限制文件数量，只会显示最近的一个文件，其他将被最新的文件覆盖
            fileCon = fileCon.slice(-1);
            setFileList(fileCon)
        },
    };
    const columns = [
        {
            title: '上传时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 150,
            align: 'center',
        },
        {
            title: '佣金ID',
            dataIndex: 'billNo',
            key: 'billNo',
            width: 170,
            align: 'center',
        },
        // {
        //     title: '财税企业',
        //     dataIndex: 'taxEnterpriseName',
        //     key: 'taxEnterpriseName',
        //     width: 200,
        //     align: 'center',
        // },
         {
            title: '来源',
            dataIndex: 'importSource',
            key: 'importSource',
            width: 110,
            align: 'center',
            render: (text,record) => {
                return record.importSource == 1 ?  '企业上传' :'平台拆分'
            }
        }, {
            title: '操作人',
            dataIndex: 'createUser',
            key: 'createUser',
            width: 110,
            align: 'center',
        }, {
            title: '账单月份',
            dataIndex: 'month',
            key: 'month',
            width: 110,
            align: 'center',
        }, {
            title: '发放方式',
            dataIndex: 'lineTypeName',
            key: 'lineTypeName',
            width: 120,
            align: 'center',
        }, {
            title: '数据处理状态',
            dataIndex: 'stepStatusName',
            key: 'stepStatusName',
            width: 160,
            align: 'center',
        },{
            title: '操作',
            key: 'action',
            width: 240,
            align: 'center',
            fixed: 'right',
            render: (text, record) => (
                <Space size="middle">{
                    record.importSource == 1 &&
                    <a type="link" onClick={() => {
                        var downloadUrl = window.location.origin + record.fileUrl,
                            fileName = record.fileName

                        var loadUrl =
                            `/wispay/common/pageUrlDownload?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&fileName=${fileName}&urlStr=${downloadUrl}`
                        window.location.href = loadUrl
                    }}>下载源文件</a >
                }
                    {record.stepStatus == 1&& record.importSource == 1 ? <a type="link" onClick={() => {
                        methodsFn.goDetail(record)
                    }}>下一步</a> : null}
                    {
                        record.stepStatus == 2&&record.importSource == 1
                            ? <Space size="middle">
                                {store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 && <a type="link" onClick={() => methodsFn.handleErrInfor(record)}>查看错误信息</a>}
                                {store.getState().userReducer.permissions.indexOf(`${path}:remove`) > -1 && <Popconfirm
                                    title="确定删除吗？"
                                    onConfirm={() => {
                                        methodsFn.handleDel(record)
                                    }}
                                    okText="确定"
                                    cancelText="取消"
                                    className="marginLeft"
                                >
                                    <a>删除</a>
                                </Popconfirm>}
                            </Space>
                            : null
                    }
                    {store.getState().userReducer.permissions.indexOf(`${path}:list`) > -1 && (record.stepStatus == 4) ? <Button type="link" onClick={() => methodsFn.goDetail(record)}>账单明细</Button> : null}
                </Space>
            ),
        },
    ];

    //组件渲染函数
    const myComponent = {
        renderSearchForm() {
            return (
                <Form form={form} initialValues={initSearch}>
                    <Row>
                        <FormItem label="数据处理状态" name="stepStatus" style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder="全部">
                                <Option value={'0'}>上传处理中</Option>
                                <Option value={'1'}>上传完成</Option>
                                <Option value={'2'}>上传失败，有错误数据</Option>
                                <Option value={'3'}>结算单生成中</Option>
                                <Option value={'4'}>已生成结算单</Option>
                                <Option value={'5'}>放弃生成结算单</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="账单月份" name="month" style={itemStyle} {...labelStyleObj}>
                            <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
                        </FormItem>
                        <FormItem label="发放方式" name="lineType" style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder="全部">
                                <Option value={1}>线上发放</Option>
                                <Option value={2}>线下发放</Option>
                            </Select>
                        </FormItem>
                        <FormItem label="来源 " name="importSource" style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder="全部">
                                <Option value={1}>企业上传</Option>
                                <Option value={2}>平台拆分</Option>
                            </Select>
                        </FormItem>
                        {/* <FormItem label="财税企业" name="taxEnterpriseCode" style={itemStyle} {...labelStyleObj}>
                            <Select>
                                <Option value={1}>企业上传</Option>
                                <Option value={2}>平台拆分</Option>
                            </Select>
                        </FormItem> */}
                        {/* <FormItem name='taxEnterpriseCode' label="财税企业" style={itemStyle} {...labelStyleObj}>
                            <Select allowClear placeholder="全部">
                            {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                            </Select>
                        </FormItem> */}
                    </Row>
                    <Row>
                        <Col span={24} md={24} lg={24}>
                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            </FormItem>
                        </Col>
                    </Row>
                </Form>
            );
        },

    }
    //方法函数
    const methodsFn = {
        dayChange(e) {      
            //天数变化
            let startDate = moment().subtract(e.target.value - 1, 'd').format('YYYY-MM-DD'),
                endDate = moment().format('YYYY-MM-DD');

            setDayValue(e.target.value)
            form.setFieldsValue({
                date: [moment(startDate), moment(endDate)]
            })
        },
        timeChange(dates, dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate), moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date: dateArr
            })

        },
        // 获取所有财税企业
        async getTaxEnterpriseListall(company){
            const res = await request.get('/common/getTaxEnterpriseList',{})
            setTaxEnterpriseLis(res)
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(form.getFieldsValue())
        },
        handleFormReset() {
            setMonth('')
            form.resetFields();
        },
        async getTableList(searchObj) {
            let result = await request.get('/company/billUpload/getRecord', {
                pageSize: pageSize,
                pageNum: pageNum,
                month: month,
                stepStatus: searchObj.stepStatus,
                lineType: searchObj.lineType,
                importSource: searchObj.importSource,
                taxEnterpriseCode: searchObj.taxEnterpriseCode,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },

        async handleErrInfor(record) {
            setVisible1(true)
            let result = await request.get('/company/billUpload/errorMessage', {
                importCode: record.importCode,
            })
            setErrList(result.list)
            setErrData(result)
        },
        handleDel(record) {
            request.get('/company/billUpload/deleteRecord', {
                importCode: record.importCode,
            }).then(res => {
                message.success(res.msg)
                methodsFn.handleSearch()
            })
        },
        async handleUp() {
            let values = await form2.validateFields()
            let data = new FormData(); //通过FormData将文件转成二进制数据
            let file = values.ffile.file
            // let file = fileList[0].originFileObj

            data.append('token', localStorage.getItem('token'))
            data.append('mobile', localStorage.getItem('mobile'))
            data.append('file', file)
            data.append('type', values.type)
            data.append('month', month2)

            axios({
                method: 'post',
                url: `/wispay/company/billUpload/uploadBillFile`,
                data: data,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(res => {
                if (res.data.success) {
                    setVisible2(false)
                    message.success(res.data.retMsg)
                    methodsFn.handleSearch() 
                } else {
                    message.error(res.data.retMsg)
                }

            }).catch(err => {
                console.log(err)
            })

        },
        goDetail(record) {
            setIsDetail(true)
            props.history.push('/admin/commissionUpload?importCode=' + record.importCode + '&taskStatusName=' + record.taskStatusName + '&lineType=' + record.lineType)
            setParmObj({
                billNo: record.billNo,
                taskStatusName: record.taskStatusName,
                lineType: record.lineType,
                stepStatus:record.stepStatus
            })
        },
        detailFn() {
            setIsDetail(false)
            props.history.push('/admin/commissionUpload')
            methodsFn.handleSearch()
        },


    }
    useEffect(() => {
        if (props.location.search) {       //解决明细页面刷新问题
            methodsFn.goDetail({
                importCode: searchParam.importCode,
                taskStatusName: searchParam.taskStatusName,
                lineType: searchParam.lineType,

            })
        }
        methodsFn.getTaxEnterpriseListall(localStorage.getItem("company"))
    }, [])
    useEffect(() => {
        methodsFn.getTableList(form.getFieldsValue())

    }, [pageNum])
    return (
        <div>
            {
                !isDetail
                    ? <div>
                        <div style={{ display: 'flex', background: '#fff', marginBottom: '20px', padding: '30px' }}>
                            <div>
                                <div>上传表格请使用固定的 表格模板
                                    {/* <a onClick={() => {
                                        var loadUrl =
                                        `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&type=QY_YJDR`
                                            window.location.href = loadUrl
                                        }}>
                                        表格模板
                                    </a> */}
                                </div>
                                <div>同一身份证号在1次批量导入中不能重复</div>
                            </div>
                            {store.getState().userReducer.permissions.indexOf(`${path}:add`) > -1 && <Button
                                type="primary"
                                style={{ marginLeft: '10px' }}
                                onClick={() => {
                                    setFileList([])
                                    form2.resetFields()
                                    setVisible2(true)
                                }}>
                                上传文件
                            </Button>}
                        </div>
                        {myComponent.renderSearchForm()}
                        <Table
                            scroll={{ x: 'max-content', y: 500 }}
                            columns={columns}
                            rowKey={(record) => record.id}
                            dataSource={tableData}
                            pagination={{
                                position: ['bottomCenter'],
                                total: total,
                                current: pageNum,
                                pageSize: pageSize,
                                showSizeChanger: false,
                                showTotal: (total) => `共 ${total} 条数据`,
                                onChange: (pageNum, pageSize) => setPageNum(pageNum)
                            }}
                        />
                    </div>
                    : parmObj.stepStatus == 1
                        ? <NextDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn} />
                        : <CommissionDetail2 isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn} />
            }

            {/* <Modal
                forceRender
                title='错误信息'
                visible={visible1}
                footer={false}
                onCancel={() => { setVisible1(false) }} >
                <p>累计已超过{errList.length}行错误，请修改后重新上传</p>
                {
                    errList.map(item => <p>{item}</p>)
                }
            </Modal> */}
            <Modal
                forceRender
                title='错误信息'
                visible={visible1}
                footer={false}
                onCancel={() => { setVisible1(false) }} >
                <p>累计已超过{errData.totalCount}行错误，请修改后重新上传</p>
                {
                    errData.list.map(item => <p key={item.id}>第{item.rowNumber}行 {item.name} {item.idNumber} ：{item.message}</p>)
                }
            </Modal>
            <Modal
                title='上传文件 '
                visible={visible2}
                onOk={methodsFn.handleUp}
                onCancel={() => { setVisible2(false) }}>
                <Form
                    form={form2}
                    {...formItemLayout}
                    initialValues={{}}>
                    <Form.Item label="企业名称" >
                        <Input value={localStorage.getItem('companyName')} disabled={true} />
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: '请选择' }]}
                        label="账单月份"
                        name="month">
                        <DatePicker style={{ width: '100%' }} onChange={(date, dateString) => setMonth2(dateString)} picker="month" />
                    </Form.Item>
                    <Form.Item
                        rules={[{ required: true, message: '请选择发放方式' }]}
                        label="发放方式"
                        name="type">
                        <Select placeholder="请选择发放方式" >
                            <Option value="1">线上发放</Option>
                            <Option value="2">线下发放</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="发放文件" name="ffile" rules={[{ required: true, message: '上传文件' }]}>
                        <Upload {...fileProps}>
                            <Button style={{marginRight: '20px'}} icon={<UploadOutlined />}>点击上传</Button>
                            <a onClick={() => window.location.href = `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&type=QY_YJDR_XX`}>下载模板</a>
                        </Upload>
                    </Form.Item>
                    {/* <Form.Item
                        label="下载模板"
                        name=""
                    >
                        <Space size="middle">
                            <a onClick={() => window.location.href = `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&type=QY_YJDR_XX`}>下载线下模板</a>
                            <a onClick={() => window.location.href = `/wispay/common/getTemplateDownloadUrl?token=${localStorage.getItem("token")}&mobile=${localStorage.getItem("mobile")}&type=QY_YJDR_XS`}>下载线上模板</a>
                        </Space>
                    </Form.Item> */}
                </Form>
            </Modal>
        </div>
    )
}

export default CommissionUpload
