import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Input,
  Radio,
  Button,
  message,
  Modal,
  DatePicker,
  Popconfirm,
  Tag,
  Space
 } from 'antd';
import qs from 'qs'
 import moment from 'moment';
 import CheckQueryDetail from './CheckQueryDetail'
 import store from '../../../../store/index'
import {request} from '../../../../utils/fetch'

 const FormItem = Form.Item;
const { Option } = Select;
 const { RangePicker } = DatePicker;
 const itemStyle = {
     width:'360px',
     marginRight:'15px'
}
 const labelStyleObj = {
    labelCol:{ span: 7},
    wrapperCol:{ span: 17}
 }

function CheckQuery(props) {
    let path = 'company:Audit'
    let searchParam = qs.parse(props.history.location.search.replace(/\?/g,''));
    const [form] = Form.useForm();
    const initSearch = {
        date:[],
        companyCode:''
    }
    const [dayValue, setDayValue] = React.useState('');
    const [tableData,setTableData] = useState([])
    const [month, setMonth] = React.useState('');
    const [auditNo, setAuditNo] = React.useState('');
    const [visible, setVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const pageSize = 20     //每页条数
    const [total,setTotal] = useState(0)
    const [pageNum,setPageNum] = useState(1)
    const [isDetail,setIsDetail] = useState(false)
    const [parmObj,setParmObj] = useState({})       //传给明细的参数
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const columns = [
        {
            title: '进入审核时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 150,
            align:'center',
        },
        // {
        //     title: '财税企业',
        //     dataIndex: 'taxEnterpriseName',
        //     key: 'taxEnterpriseName',
        //     width: 200,
        //     align:'center',
        // },
         {
            title: '佣金账单ID',
            dataIndex: 'billNo',
            key: 'billNo',
            width: 180,
            align:'center',
            
        },  {
            title: '账单月份',
            dataIndex: 'month',
            key: 'month',
            width: 180,
            align:'center',
        },
       {
            title: '进入审核的数据条数',
            dataIndex: 'staffCount',
            key: 'staffCount',
            width: 180,
            align:'center',
            
        }, 
        {
            title: '进入审核的佣金（元）',
            dataIndex: 'totalCommissionAmount',
            key: 'totalCommissionAmount',
            width: 180,
            align:'center',
            
        },  {
            title: '审核通过的数据条数',
            dataIndex: 'passCount',
            key: 'passCount',
            width: 180,
            align:'center',
            
        }, {
            title: '审核拒绝的数据条数',
            dataIndex: 'rejectCount',
            key: 'rejectCount',
            width: 180,
            align:'center',
            
        },{
            title: '审核状态',
            dataIndex: 'auditStatusName',
            key: 'auditStatusName',
            width: 150,
            align:'center',
            
        },  {
            title: '结算单状态',
            dataIndex: 'createSettlementStatusName',
            key: 'createSettlementStatusName',
            width: 150,
            align:'center',
        }, 
        {
        title: '操作',
        key: 'action',
        width: 200,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => {methodsFn.goDetail(record)}}>明细</a>}
                {/* {
                    store.getState().userReducer.permissions.indexOf(`${path}:addSettlement`)>-1&&(record.createSettlementStatusName == '待生成')
                    ? <a onClick={() => {
                        setVisible(true)
                        setAuditNo(record.auditNo)
                    }}>生成结算单</a>
                    :null
                } */}
               
            </Space>
        ),
        },
    ];
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    <FormItem label="进入审核时间" name="date" style={{marginLeft:'15px'}} >
                        <RangePicker 
                            onChange={methodsFn.timeChange}
                        />
                    </FormItem>
                    <FormItem style={{with:'600px',marginLeft:'26px'}} label="">
                        <Radio.Group value={dayValue} onChange={methodsFn.dayChange}>
                            <Radio value={1}>今天</Radio>
                            <Radio value={7}>近7天</Radio>
                            <Radio value={30}>近30天</Radio>
                        </Radio.Group>
                    </FormItem>
                </Row>
                <Row>
                    {/* <FormItem name='taxEnterpriseCode' label="财税企业" style={itemStyle} {...labelStyleObj}>
                        <Select allowClear placeholder="全部">
                        {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </FormItem> */}
                    <FormItem label="审核状态" name="auditStatus" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部">
                            <Option value={'SHZ'}>审核中</Option>
                            <Option value={'SHWC'}>审核完成</Option>
                            <Option value={'FQ'}>企业放弃</Option>
                            {/* <Option value={'ZDTG'}>自动审核通过</Option> */}
                        </Select>
                    </FormItem>
                    <FormItem  label="结算单状态" name="settlementQueryType" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部">
                            <Option value={0}>未生成</Option>
                            <Option value={1}>已生成</Option>
                            <Option value={2}>放弃生成</Option>
                        </Select>
                    </FormItem>
                    <FormItem label="账单月份" name="month" style={itemStyle} {...labelStyleObj}>
                        <DatePicker onChange={(date, dateString) => setMonth(dateString)} picker="month" />
                    </FormItem>
                  
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        dayChange(e) {                  //天数变化
            let startDate = moment().subtract(e.target.value - 1,'d').format('YYYY-MM-DD'),
            endDate = moment().format('YYYY-MM-DD');
        
            setDayValue(e.target.value)
            form.setFieldsValue({
                date:[moment(startDate),moment(endDate)]
            })
        },
        timeChange(dates,dateStrings) {   //日期变化
            let startDate = dateStrings[0],
                endDate = dateStrings[1];
            let dateArr = [];
            dates ? dateArr = [moment(startDate),moment(endDate)] : dateArr = []
            form.setFieldsValue({
                date:dateArr
            })
         
        },
        handleSearch() {
            setPageNum(1)
            methodsFn.getTableList(form.getFieldsValue())
        },
        handleFormReset(){
            setMonth('')
            setDayValue('')
            form.resetFields();
        },
        async getTableList(searchObj){
            let beginTime = '',
                endTime = '';
            if(searchObj.date.length > 1){
                beginTime = searchObj.date[0].format('YYYY-MM-DD')
                endTime = searchObj.date[1].format('YYYY-MM-DD')
            }else{
                beginTime = ''
                endTime = ''
            }
            let result = await request.get('/company/audit/getList',{
                beginTime:beginTime,
                endTime:endTime,
                month:month,
                auditStatus:searchObj.auditStatus,
                settlementQueryType:searchObj.settlementQueryType,
                taxEnterpriseCode:searchObj.taxEnterpriseCode,
                pageSize:pageSize,
                pageNum:pageNum,
            })
               setTotal(result.totalCount)
               setTableData(result.list)
        },
   
        goDetail(record){
            setIsDetail(true)
            props.history.push('/admin/checkQuery?auditNo='+record.auditNo)
            setParmObj({
                auditNo:record.auditNo,
            })
        },
        detailFn(){
            setIsDetail(false)
            props.history.push('/admin/checkQuery')
        },
        handleOk(){
            setLoading(true);
            request.post('/company/audit/addSettlement',{
                auditNo:auditNo,
            }).then(res => {
                setVisible(false);
                setLoading(false);
                message.success('操作成功')
                methodsFn.handleSearch()
            }).catch(err => setLoading(false))
        },
         // 获取所有财税企业
        async getTaxEnterpriseListall(company){
            const res = await request.get('/common/getTaxEnterpriseList',{})
            setTaxEnterpriseLis(res)
        }
    }

    useEffect(() => {
        if(props.location.search){       //解决明细页面刷新问题
            methodsFn.goDetail({
                auditNo:searchParam.auditNo,
            })
        }
        methodsFn.getTaxEnterpriseListall(localStorage.getItem("company"))
    },[])
    useEffect(() => {
        methodsFn.getTableList(form.getFieldsValue())
    },[pageNum])

    return (
             <div>
                 {
                     !isDetail?
                     <div>
                     {myComponent.renderSearchForm()}
                     <Table 
                         scroll={{ x: 'max-content',y: 500 }}
                         columns={columns} 
                         dataSource={tableData} 
                         rowKey={(record) => record.billNo}
                         pagination={{
                            position: ['bottomCenter'],
                            total: total,
                            current: pageNum,
                            pageSize: pageSize,
                            showSizeChanger:false,
                            showTotal:(total) =>  `共 ${total} 条数据` ,
                            onChange: (pageNum, pageSize) => setPageNum(pageNum)
                        }}
                     />
                 </div>
                 :<CheckQueryDetail isDetail={isDetail} parmObj={parmObj} callFn={methodsFn.detailFn}/>
                 }
                
                <Modal
                    title='确认框'
                    visible={visible}
                    onOk={methodsFn.handleOk}
                    onCancel={() => setVisible(false)}>
                    <p>确定对审核通过的数据生成结算单并下载审核拒绝的数据表格么？</p>
                    <p>
                    线上发放则是在生成结算单后，去“结算支付”中进行支付；
                    线下发放则是在生成结算单后由平台确认发放完成，无需在线支付。 </p>
                </Modal>
            </div>
    )
}

export default CheckQuery
