import axios from 'axios'
import Qs from 'qs'
import {message} from 'antd';
import {baseURL} from './envConfig'
// axios 配置
axios.defaults.timeout = 20000;
axios.defaults.baseURL = baseURL


// http request 拦截器
// axios.interceptors.request.use(
//     config => {
//         if (localStorage.getItem('token')) {
//             config.headers.Authorization =  `token=${localStorage.getItem('token')}`;    //添加了自定义头，后端没设置会一直显示跨域
//         }
//         return config;
//     },
//     err => {
//         alert('请求超时')
//         return Promise.reject(err);
// });

// // http response 拦截器
axios.interceptors.response.use(
  response => {
    // console.log('----',response)
    if (response.data.retCode === '000302') {
      localStorage.removeItem('mobile')
      // localStorage.clear()
      window.location = '/login'
    }
    return response;
  },
  err => {
    console.log('-----',err)

    // window.location = '/#/login'
      if (err.response.data.status == 504||err.response.data.status == 404) {
        message.error('网络错误')
      } else if (err.response.data.status == 403) {
        message.error('权限不足,请联系管理员')
      }else if(err.response.data.status == 401){
        // 401 清除token信息并跳转到登录页面
        // store.commit(types.LOGOUT);
        //跳转到登录页面
        // window.location = '/#/login'
      
      }else {
        message.error('未知错误')
      }
      console.log(JSON.stringify(err));//console : Error: Request failed with status code 402
      return Promise.reject(err.response.data)
  }
);
let base = process.env.NODE_ENV === 'production' ? '/wispay' :'';
if(baseURL.includes('192')){
  base = ''
}
const post = (url, params) => {
  let data = Object.assign({
    token:localStorage.getItem('token'),
    mobile:localStorage.getItem('mobile')
  },params)
  return new Promise((resolve, reject) => {
    axios({
      method: 'post',
      url: `${base}${url}`,
      data: data,
      transformRequest: [function (data) {
        return Qs.stringify(data,  {allowDots: true})
      }],
    }).then(res => {
      try {
        if(res.data.success){
          //操作类成功data为null
          resolve(res.data.data || {msg: res.data.retMsg})
        }else{
            message.error(res.data.retMsg)
            reject({msg: res.data.retMsg})
        }
      } catch (error) {
        console.log(error)
      }
   
    })
})

}

const get = (url, params) => {
  let data = Object.assign({
    token:localStorage.getItem('token'),
    mobile:localStorage.getItem('mobile')
  },params)

  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: `${base}${url}`,
      params: data,
      paramsSerializer: function(params) {
        return Qs.stringify(params, {arrayFormat: 'repeat'})
      },
    }).then(res => {
      try {
        if(res.data.success){
          //操作类成功data为null
          resolve(res.data.data || {msg: res.data.retMsg})
        }else{
            message.error(res.data.retMsg)
            reject({msg: res.data.retMsg})
        }
      } catch (error) {
        console.log(error)
      }
   
    })
})
}

const downExport = (url, params) => {
  let obj = Object.assign({
    token:localStorage.getItem('token'),
    mobile:localStorage.getItem('mobile')
  },params)

  let parmStr = ''
  for (let item in obj) {
     if (obj[item] == null || obj[item] == 'undefined') {
         obj[item] = ''
     }
     parmStr += item + '=' + obj[item] + '&'
 }
  window.location.href = `${base}${url}?${parmStr}`

}


/**
 * 自定义重命名下载
 * @param {arry} url 下载链接
 * @param {string} name 文件名称
 */
const renameDownload = (url, name) => {
  let fileName = name
  getBlob(url).then((blob) => {
      DownloadItem(blob, fileName)
  })
}
const getBlob = (url)=> {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        console.log(1111)
        resolve(xhr.response)
      }
    }
    xhr.send()
  })
}
const DownloadItem = (blob, fileName)=> {
  let link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.download = fileName
  link.click()
}

export let request = {get,post,downExport,renameDownload}


