import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import {request} from '../../../utils/fetch'


function Home() {
    const [dataObj, setDataObj] = React.useState({});
    
    const getIndexData = () => {
       request.get('/company/index/indexData').then(res => {
        setDataObj(res)
       })
    }
    useEffect(() => {
        getIndexData()
    },[])
    return (
        <div>
            {/* <h3 className="home-h">Hi，欢迎进入 { localStorage.getItem('companyName')}</h3> */}
            <h3 className="home-title">Hi，欢迎进入 { localStorage.getItem('companyName')}</h3>
            <Row  justify="space-between">
                <Col className="home-top b1">
                    <div>个人签约数</div>
                    <h3>{dataObj.userSignCount}</h3>
                </Col>
                <Col  className="home-top b2">
                    <div>企业账户余额</div>
                    <h3>{dataObj.companyAccount}</h3></Col>
                <Col className="home-top b3">
                    <div>发包信息</div>
                    <h3>{dataObj.sendFlag?'已维护':'未维护'}</h3>
                </Col>
            </Row>
            <Row  justify="space-between" style={{marginTop:'10px'}}>
                <Col  className="home-top b4">
                    <div>当月佣金</div>
                    <h3>{dataObj.commissionFlag?'已上传佣金':'未上传佣金'}</h3>
                </Col>
                <Col className="home-top b5">
                    <div>线上待支付</div>
                    <h3>{dataObj.onlineWaitPaymentAmount}</h3>
                </Col>
                <Col className="home-top b6">
                    <div>开票申请</div>
                    <h3>{dataObj.commissionFlag?'已开票':'未开票'}</h3>
                </Col>
            </Row>
            <h3 className="home-title">操作指引</h3>
            <Row style={{marginTop:'10px','background': '#bddbfd'}}>
                <Col span={24} className="home-top b7">
                </Col>
            </Row>
    
           
        </div>
    )
}

export default Home
