import React, { useState, useEffect } from 'react'
import {
  PageHeader,
  Image,
  Divider,
  Button,
  Popconfirm,
  Table,
  Form,
  Skeleton,
  message,
  Input,
  Space,
  Modal
} from 'antd'

import './CheckDetail.css'
import { getCompanyDetail, deleteReceipt, insertReceipt, updateReceipt, editRightTel, getConfirmationUrl } from './api'
import store from '../../../store/index'

const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 12 },
}
const CompanyInfoPage = (props) => {
  let path = 'company:CompanyBase'
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [detail, setDetail] = useState({})
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [visible, setVisible] = useState(false)
  const [receiptNo, setReceiptNo] = useState()
  const [type, setType] = useState()
  const [configVoList, setConfigVoList] = useState([])

  const params = props.match.params
  const modalForm = {
    receiptPhone: '',
    receiptName: '',
    receiptAddress: ''
  }

  useEffect(() => {
    getDetail()
  }, [])
  // 获取详情
  const getDetail = async () => {
    try {
      setLoading(true)
      const result = await getCompanyDetail({ companyCode: params.id })
      console.log('1111', result, params.id)
      if (result) {
        setDetail(result)
        let list = result.serviceConfigVoList.map(e=>e.planType)
        setConfigVoList(list)
      }
      console.log('res', result)
      setLoading(false)
    } catch (err) {
      setLoading(false)
      console.log('__企业详情', err)
    }

  }
  const tableColumns = [
    {
      title: '收件人',
      dataIndex: 'receiptName',
      key: 'receiptName'
    }, {
      title: '联系电话',
      dataIndex: 'receiptPhone',
      key: 'receiptPhone'
    }, {
      title: '收件地址',
      dataIndex: 'receiptAddress',
      key: 'receiptAddress'
    }, {
      title: '操作',
      key: 'control',
      fixed: 'right',
      align: 'center',
      render: (record) => {
        const control = []
        store.getState().userReducer.permissions.indexOf(`${path}:updateReceipt`) > -1 && control.push(<a key="1" onClick={() => modify(record)}>修改</a>)
        store.getState().userReducer.permissions.indexOf(`${path}:deleteReceipt`) > -1 && control.push(
          <Popconfirm
            title="是否确定要删除?"
            onConfirm={() => deleteData(record)}
            key="2"
            okText="确定"
            cancelText="取消"
          >
            <a>删除</a>
          </Popconfirm>)
        const result = []
        control.forEach(item => {
          result.push(item)
        })

        return (
          <Space>{result}</Space>
        )
      }
    }

  ]
  const refresh = () => {
    getDetail()
  }
  const modify = async (record) => {
    setType('modify')
    setVisible(true)
    setReceiptNo(record.receiptNo)
    form.setFieldsValue({
      receiptPhone: record.receiptPhone,
      receiptName: record.receiptName,
      receiptAddress: record.receiptAddress
    })
  }
  const deleteData = async (record) => {
    try {
      const res = await deleteReceipt({ receiptNo: record.receiptNo })
      if (res) {
        message.success('删除成功')
        refresh()
      }
    } catch (err) {
      console.log('__删除', err)
    }

  }
  // 新增
  const insert = () => {
    setType('insert')
    setVisible(true)
  }
  const goPage = () => {
    const data = {
      companyName: detail.companyName
    }
    props.history.push({ pathname: `/admin/historyConfig/${detail.companyCode}`, state: data })
  }
  const onCancel = () => {
    setVisible(false)
    form.resetFields()
  }

  const handleOk = async () => {
    const data = {
      receiptPhone: form.getFieldsValue().receiptPhone,
      receiptName: form.getFieldsValue().receiptName,
      receiptAddress: form.getFieldsValue().receiptAddress
    }
    try {
      await form.validateFields()
      let res
      setConfirmLoading(true)
      if (type === 'insert') {
        res = await insertReceipt(data)
      }
      if (type === 'modify') {
        res = await updateReceipt(Object.assign({}, data, { receiptNo: receiptNo }))
      }
      if (res) {
        setVisible(false)
        refresh()
      }
      setConfirmLoading(false)
    } catch (err) {

    }
  }

  return (
    <>
      <PageHeader
        title="查看"
        onBack={() => props.history.goBack()}
      />

      <div className="mes-content">
        <Skeleton loading={loading} active>
          <div style={{ position: 'relative' }}>
            <div className="mes-box">
              <div className="box-top">
                <div className="title">基本信息</div>
              </div>
              <div className="box-row">
                <span className="row-label">企业名称</span>
                <span>{detail.companyName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">项目性质</span>
                <span>{detail.projectTypeName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">项目名称</span>
                <span>{detail.projectName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">行业分类</span>
                <span>{detail.industryTypeName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">纳税人类型</span>
                <span>{detail.taxpayerTypeName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">统一社会信用代码</span>
                <span>{detail.taxpayerNumber}</span>
              </div>
              <div className="box-row">
                <span className="row-label">单位地址</span>
                <span>{detail.address}</span>
              </div>
              <div className="box-row">
                <span className="row-label">注册电话</span>
                <span>{detail.registeredPhone}</span>
              </div>
              <div className="box-row">
                <span className="row-label">开户行名称</span>
                <span>{detail.bankName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">银行账号</span>
                <span>{detail.bankNumber}</span>
              </div>
              <div className="box-row">
                <span className="row-label">法人姓名</span>
                <span>{detail.legalPersonName}</span>
              </div>
              <div className="box-row">
                <span className="row-label">联系邮箱</span>
                <span>{detail.contactEmail}</span>
              </div>
              <div className="box-row">
                <span className="row-label">营业执照</span>
                <span>
                  {
                    detail.licenseFileUrl &&
                    <Image
                      width={100}
                      src={detail.licenseFileUrl}
                    />
                  }

                </span>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', right: '30px', top: '60px' }}>
                {configVoList.includes(2) && 
                  <div style={{ width: '220px' }}>
                    <Image width={200} src={detail.unitPictureUrl} />
                    <a href={detail.unitPictureUrl} target="_blank" download={detail.unitPictureUrl} style={{ display: 'block', textAlign: 'center' }}>下载二维码</a>
                  </div>
                }
                {configVoList.includes(1) &&
                  <div style={{ width: '220px' }}>
                    <Image width={200} src={detail.taxPictureUrl} />
                    <a href={detail.taxPictureUrl} target="_blank" download={detail.taxPictureUrl} style={{ display: 'block', textAlign: 'center' }}>下载二维码</a>
                  </div>
                }
                {configVoList.includes(3) &&
                  <div style={{ width: '220px' }}>
                    <Image width={200} src={detail.entrustPictureUrl} />
                    <a href={detail.entrustPictureUrl} target="_blank" download={detail.entrustPictureUrl} style={{ display: 'block', textAlign: 'center' }}>下载二维码</a>
                  </div>
                }
              </div>
            </div>
          </div>
          <Divider />
          <div className="mes-box">
            <div className="box-top">
              <div className="title">佣金审核</div>
            </div>
            <div className="box-row">
              <span className="row-label">人工审核</span>
              <span>{detail.auditModeName}</span>
            </div>
          </div>
          <Divider />
          <div className="mes-box">
            <div className="box-top">
              <div className="title">
                落地配置　({detail.serviceConfigVoList && detail.serviceConfigVoList.length > 0 && detail.serviceConfigVoList[0].effectTime + '开始生效'})
              </div>
              {store.getState().userReducer.permissions.indexOf(`${path}:detail`) > -1 && <a onClick={goPage}>历史配置信息</a>}
            </div>
            {detail.serviceConfigVoList && detail.serviceConfigVoList.length > 0 && detail.serviceConfigVoList.map(item => (
              <div key={item.planType}>
                {item.planType === 1 && <div className="sub-title">临时税务登记</div>}
                {item.planType === 2 && <div className="sub-title">个体工商户</div>}
                {item.planType === 3 && <div className="sub-title">委托代征</div>}
                {/* <div className="box-row">
                  <span className="row-label">财税企业</span>
                  <span>{item.taxEnterpriseName}</span>
                </div> */}
                <div className="box-row">
                  <span className="row-label">税费</span>
                  <span>单笔打款佣金{item.taxPoint}%</span>
                </div>
                <div className="box-row">
                  <span className="row-label">是否返税</span>
                  <span>{item.backTaxFlag === 1 ? '返税' : '不返税'}</span>
                </div>
                {
                  item.backTaxFlag === 0 ?
                    <div className="box-row">
                      <span className="row-label">开票</span>
                      <span>开票金额=佣金+服务费+税费</span>
                    </div>
                    :
                    <>
                      <div className="box-row">
                        <span className="row-label">返税</span>
                        <span>单笔打款佣金的{item.backTaxPoint}%</span>
                      </div>
                      <div className="box-row">
                        <span className="row-label">开票</span>
                        <span>{item.taxTicketFlag == 0 ? '返税不开票（开票金额=佣金+服务费+税费-返税）' : '返税开票（开票金额=佣金+服务费+税费）'}</span>
                      </div>
                    </>
                }
                <div className="box-row">
                  <span className="row-label">常规服务费</span>
                  <div>
                    {item.serviceLadderFlag === 1 &&
                      <>
                        <p>阶梯式</p>
                        {
                          item.companyConfigDetailList && item.companyConfigDetailList.map(p =>
                            <p key={p.sort}>第{p.sort}阶段：{p.minAmount}≤当月（自然月）累计批量佣金金额 &lt; {p.maxAmount}万时，单笔佣金的{item.serviceType === 1 ? '固定金额' : '百分比'}，每笔{p.value}{item.serviceType === 1 ? '元' : '%'}</p>
                          )
                        }

                      </>
                    }
                    {
                      item.serviceLadderFlag === 0 &&
                      <>
                        <p>非阶梯</p>
                        <p>{item.serviceType === 1 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔{item.serviceValue}{item.serviceType === 1 ? '元' : '%'}</p>
                      </>
                    }
                  </div>
                </div>
                <div className="box-row">
                  <span className="row-label">大金额服务费</span>
                  <span>单笔佣金≥3万，每笔{item.bigServicePoint}%</span>
                </div>
              </div>
            ))}
          </div>
          <Divider />
          <div className="mes-box">
            <div className="box-top">
              <div className="title">企业管理员</div>
              {/* <Popconfirm placement="top" title="是否确定将密码重置为手机号后六位的初始密码？" onConfirm={confirm} okText="确认" cancelText="取消">
                  <Button shape="round">重置登录密码</Button>
                </Popconfirm> */}
            </div>
            <div className="box-row">
              <span className="row-label">姓名</span>
              <span>{detail.manageName}</span>
            </div>
            <div className="box-row">
              <span className="row-label">手机号</span>
              <span>{detail.managePhone}</span>
            </div>
          </div>
          <Divider />
          <div className="mes-box">
            <div className="box-top">
              <div className="title">发票收件信息</div>
              {store.getState().userReducer.permissions.indexOf(`${path}:addReceipt`) > -1 && <Button type="primary" shape="round" style={{ marginRight: '30px' }} onClick={() => insert()}>新增</Button>}
            </div>
            <Table columns={tableColumns} dataSource={detail.companyReceiptList} pagination={false} rowKey={record => record.receiptNo}></Table>
          </div>
        </Skeleton>
      </div>

      <Modal
        title="收件信息"
        visible={visible}
        onCancel={onCancel}
        confirmLoading={confirmLoading}
        onOk={handleOk}
        width={740}>
        <Form form={form} initialValues={modalForm}>
          <Form.Item {...formItemLayout} name="receiptName" rules={[{ required: true }]} disabled label="收件人">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item {...formItemLayout} name="receiptPhone" rules={[{ required: true }]} label="联系电话">
            <Input placeholder="请输入" />
          </Form.Item>
          <Form.Item {...formItemLayout} name="receiptAddress" rules={[{ required: true }]} label="收件地址">
            <Input placeholder="请输入" />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )

}

export default CompanyInfoPage