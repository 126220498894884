// 线上明细
import React, { useState, useEffect } from 'react'
import {
    Row,
    Col,
    Table,
    Select,
    Form,
    Input,
    Button,
    Space,
    message,
    Popconfirm,
    Modal,
    PageHeader,
    Typography
} from 'antd';
import { withRouter } from "react-router-dom";
import { request } from '../../../../utils/fetch'
import { useDebounce, useThrottle } from '../../../../utils/auth'
import store from '../../../../store/index'
const { Text } = Typography;
const { Option } = Select;
const FormItem = Form.Item;
const itemStyle = {
    width: '300px',
    marginRight: '15px'
}
const labelStyleObj = {
    labelCol: { span: 9 },
    wrapperCol: { span: 15 }
}
const wrapStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '900px'
}
const resultLeft = {
    display: 'flex',
    background: '#fff',
    marginTop: '20px',
    alignItems: 'center',
    padding: '20px 0 0 30px',
}
function NextDetail(props) {
    let path = 'company:BillUpload'
    const [form] = Form.useForm();
    const { parmObj } = props
    const initSearch = {
        keyword: '',
    }
    const [tableData, setTableData] = useState([])
    const [visible, setVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [resultObj, setResultObj] = React.useState({
        TG: {
            rowCount: ''
        },
        JRSH: {
            rowCount: ''
        }
    });

    const pageSize = 20     //每页条数
    const [total, setTotal] = useState(0)
    const [pageNum, setPageNum] = useState(1)

    let columns = [
        {
            title: '姓名',
            dataIndex: 'name',
            key: 'name',
            width: 150,
            align: 'center',

        }, {
            title: '身份证号',
            dataIndex: 'idNumber',
            key: 'idNumber',
            width: 150,
            align: 'center',

        }, {
            title: '检查结果',
            dataIndex: 'checkResultName',
            key: 'checkResultName',
            width: 150,
            align: 'center',

        }, {
            title: '佣金（元)',
            dataIndex: 'commissionAmount',
            key: 'commissionAmount',
            width: 150,
            align: 'center',

        },
        {
            title: '落地方案',
            dataIndex: 'planTypeName',
            key: 'planTypeName',
            width: 150,
            align:'center',
            
        },
        {
            title: '打款备注',
            dataIndex: 'content',
            key: 'content',
            width: 150,
            align: 'center',

        }, {
            title: '服务费（元）',
            dataIndex: 'serviceAmount',
            key: 'serviceAmount',
            width: 150,
            align: 'center',

        }, {
            title: '税费（元）',
            dataIndex: 'taxAmount',
            key: 'taxAmount',
            width: 150,
            align: 'center',
        },

    ];
    if (parmObj.lineType != 1) {
        columns.push({
            title: '收款卡号',
            dataIndex: 'bankNumber',
            key: 'bankNumber',
            width: 150,
            align: 'center',

        }, {
            title: '开户行',
            dataIndex: 'bankName',
            key: 'bankName',
            width: 150,
            align: 'center',

        })
    }

    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
                <div>
                    <div style={resultLeft}>
                        <div style={{ width: '70%',minWidth:'840px' }}>
                            <div style={{ paddingTop: '20px' }}>
                                {resultObj.TG.rowCount &&
                                    <Space>
                                        <Text>检查通过：</Text>
                                        <Text>{resultObj.TG.rowCount}条数据，</Text>
                                        <Text>合计{parmObj.lineType == 1 ? '线上' : '线下'}放款{resultObj.TG.totalAmountCount}</Text>
                                        <Text>（向{resultObj.TG.rowCount}人佣金打款￥{resultObj.TG.commissionAmountCount}</Text>
                                        <Text>向平台支付服务费￥{resultObj.TG.serviceAmountCount}</Text>
                                        <Text>向平台支付税费￥{resultObj.TG.taxAmountCount}）</Text>
                                    </Space>
                                }
                            </div>
                            <div>{
                                resultObj.JRSH.rowCount &&
                                <Space>
                                    <Text type="danger">进入人工审核：</Text>
                                    <Text type="danger">{resultObj.JRSH.rowCount}条数据，</Text>
                                    <Text type="danger">合计放款￥{resultObj.JRSH.totalAmountCount} </Text>
                                    <Text>（向{resultObj.JRSH.rowCount}人佣金打款 ￥{resultObj.JRSH.commissionAmountCount}</Text>
                                    <Text>向平台支付服务费￥{resultObj.JRSH.serviceAmountCount} </Text>
                                    <Text>向平台支付税费￥{resultObj.JRSH.taxAmountCount}）</Text>
                                </Space>}
                            </div>

                        </div>
                        <div>
                            {
                                store.getState().userReducer.permissions.indexOf(`${path}:createSettlement`) > -1 && 
                                <Button type="primary" onClick={() => setVisible(true)} style={{marginBottom:'10px'}}>生成结算单</Button>
                            }
                            {
                                store.getState().userReducer.permissions.indexOf(`${path}:removeSettlement`) > -1 && 
                                <Popconfirm
                                    title={`确定放弃生成结算单么？`}
                                    onConfirm={() => methodsFn.handleCancel()}
                                    okText="确定"
                                    cancelText="取消"
                                    className="marginLeft"
                                >
                                    <Button style={{ marginLeft: 10 }} >放弃生成</Button>
                                </Popconfirm>}
                        </div>

                    </div>
                    <Form form={form} >
                        <Row>
                            <FormItem label="检查结果" name="checkResult" style={itemStyle} {...labelStyleObj}>
                                <Select>
                                    <Option value={'JRSH'}>进入人工审核</Option>
                                    <Option value={'TG'}>检查通过</Option>
                                </Select>
                            </FormItem>
                            <FormItem label="关键字" name="keyword" style={itemStyle} {...labelStyleObj}>
                                <Input placeholder="姓名丨身份证号" />
                            </FormItem>

                            <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                                <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                                <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                            </FormItem>
                        </Row>
                    </Form>
                </div>
            );
        },

    }
    //方法函数
    const methodsFn = {
        handleSearch() {
            setPageNum(1)
            var obj = {
                checkResult: form.getFieldsValue().checkResult,
                keyword: form.getFieldsValue().keyword,
                billNo: parmObj.billNo,
            }
            methodsFn.getTableList(obj)
        },
        handleFormReset() {
            form.resetFields();
        },
        async getTableList(searchObj) {
            let result = await request.get('/company/billUpload/getPreviewList', {
                ...searchObj,
                pageSize: pageSize,
                pageNum: pageNum,
            })
            setTotal(result.totalCount)
            setTableData(result.list)
        },
        getImportStat() {
            request.get('/company/billUpload/getPreviewStat', {
                billNo: parmObj.billNo,
            }).then(res => {
                console.log(res)
                setResultObj(res)
            })
        },
        handleOk: useThrottle(()=> {
            setLoading(true);
            request.post('/company/billUpload/createSettlement', {
                billNo: parmObj.billNo,
            }).then(res => {
                setVisible(false);
                setLoading(false);
                message.success(res.msg)
                props.callFn()
            }).catch(() => setLoading(false))
        }, 1000),
        handleCancel() {
            request.post('/company/billUpload/removeSettlement', {
                billNo: parmObj.billNo,
            }).then(res => {
                message.success(res.msg)
                props.callFn()
            }).catch(() => setLoading(false))
        }

    }
    useEffect(() => {
        methodsFn.getImportStat()

    }, [])
    useEffect(() => {
        var obj = {
            checkResult: form.getFieldsValue().checkResult,
            keyword: form.getFieldsValue().keyword,
            billNo: parmObj.billNo,
        }
        methodsFn.getTableList(obj)

    }, [pageNum])

    return (
        <div>
            <PageHeader className="site-page-header" title="下一步" onBack={props.callFn} subTitle="">
                {/* <h2>放款金额自动核算</h2>
                {
                   
                    <p style={wrapStyle}>
                        <span>合计放款金额￥{( (Number(resultObj.JRSH.totalAmountCount?resultObj.JRSH.totalAmountCount:0) + Number(resultObj.TG.totalAmountCount?resultObj.TG.totalAmountCount:0)).toFixed(2) )}</span>  
                        <span>（向{Number(resultObj.JRSH.rowCount?resultObj.JRSH.rowCount:0)  + Number(resultObj.TG.rowCount?resultObj.TG.rowCount:0) }人
                        佣金打款￥{(Number(resultObj.JRSH.commissionAmountCount?resultObj.JRSH.commissionAmountCount:0)  + Number(resultObj.TG.commissionAmountCount?resultObj.TG.commissionAmountCount:0) ).toFixed(2)}</span>
                        <span>向平台支付服务费￥{(Number(resultObj.JRSH.serviceAmountCount?resultObj.JRSH.serviceAmountCount:0) + Number(resultObj.TG.serviceAmountCount?resultObj.TG.serviceAmountCount:0)).toFixed(2)} </span>
                        <span>向平台支付税费￥{(Number(resultObj.JRSH.taxAmountCount?resultObj.JRSH.taxAmountCount:0) + Number(resultObj.TG.taxAmountCount?resultObj.TG.taxAmountCount:0) ).toFixed(2)} ）</span>
                    </p>
                }
                <p>企业账户可用余额  ￥0.5 </p> */}
            </PageHeader>

            {myComponent.renderSearchForm()}
            <Table
                scroll={{ x: 'max-content', y: 500 }}
                columns={columns}
                dataSource={tableData}
                rowKey={(record) => record.idNumber}
                pagination={{
                    position: ['bottomCenter'],
                    total: total,
                    current: pageNum,
                    pageSize: pageSize,
                    showSizeChanger: false,
                    showTotal: (total) => `共 ${total} 条数据`,
                    onChange: (pageNum, pageSize) => setPageNum(pageNum)
                }}
            />
            <Modal
                title='确认框'
                visible={visible}
                loading={loading}
                onOk={methodsFn.handleOk}
                onCancel={() => setVisible(false)}>
                <p>确定生成结算单数据？</p>
                <p>检查通过及人工审核通过的数据生成结算单。</p>
                <p>线上发放则是在生成结算单后，去企业端“结算支付”中进行支付；线下发放则是在生成结算单后由平台确认发放完成，无需在线支付。</p>
            </Modal>
        </div>
    )
}

export default withRouter(NextDetail)
