import React, { useState, useEffect } from 'react'
import {
  Input,
  Card,
  PageHeader,
  Row,
  Col,
  Empty
} from 'antd'
import { getHistoryConfig } from './api'

function CompanyHistoryConfig(props) {
  const [loading, setLoading] = useState(false)
  const state = props.location.state
  const [configData, setConfigData] = useState([])
  useEffect(() => {
    getConfig()
  }, [])
  const getConfig = async() => {
    try {
      setLoading(true)
      const res = await getHistoryConfig()
      console.log(res)
      setLoading(false)
      if (res) {
        setConfigData(res)
      } else {
      }
    } catch(err) {
      setLoading(false)
      console.log('__获取历史配置', err)
    }
  }
  return (
    <PageHeader title="历史配置" onBack={() => props.history.goBack()}>
      <div className="history-config">
        <div className="top">企业名称：<Input value={state.companyName} style={{width: '320px'}}></Input></div>
        {
          configData.length ? configData.map((item, index) => (
            <Card title={item.effectTime} loading={loading} key={'config' + index} style={{margin: '10px 0'}}>
            {
              item.list.length > 0 && item.list.map((node, index) => (
                <div key={index}>
                  {node.planType === 1 && <div className="card-title" style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>临时税务登记</div>}
                  {node.planType === 2 && <div className="card-title" style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>个体工商户</div>}
                  {node.planType === 3 && <div className="card-title" style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '10px'}}>委托代征</div>}
                  <Row gutter={[4, 10]}>
                    {/* <Col span="12">
                      <span>财税企业：</span>
                      <span>{node.taxEnterpriseName}</span>
                    </Col> */}
                    <Col span="12">
                      <span>税费：</span>
                      <span>单笔打款佣金的{node.taxPoint}%</span>
                    </Col>
                    <Col span="12">
                      <span>是否返税：</span>
                      <span>{node.backTaxFlag === 1 ? '返税' : '不返税'}</span>
                    </Col>
                    {
                    node.backTaxFlag === 0 ? 
                      <Col span="12">
                        <span>开票：</span>
                        <span>开票金额=佣金+服务费+税费</span>
                      </Col>
                      : 
                      <>
                        <Col span="12">
                          <span>返税：</span>
                          <span>单笔打款佣金的{node.backTaxPoint}%</span>
                        </Col>
                        <Col span="12">
                          <span>开票：</span>
                          <span>{node.taxTicketFlag === 0 ? '返税不开票（开票金额=佣金+服务费+税费-返税）' : '返税开票（开票金额=佣金+服务费+税费）'}</span>
                        </Col>
                      </>
                    }
                    <Col span="24" style={{display: 'flex', justifyContent: 'flex-start'}}>
                      <span>常规服务费：</span>
                      {node.serviceLadderFlag === 1 && 
                        <div>
                          <span>阶梯式</span>
                          {
                            node.companyConfigDetailList.length > 0 && node.companyConfigDetailList.map(p=>
                              <div>第{p.sort}阶段：{p.minAmount}≤当月（自然月）累计批量佣金金额 &lt; {p.maxAmount}万时，单笔佣金的{node.serviceType === 1 ? '固定金额' : '百分比'}，每笔{p.value}{node.serviceType === 1 ? '元' : '%'}</div>
                            )
                          } 
                        </div>
                      }
                      {
                        node.serviceLadderFlag === 0 && 
                        <div>
                          <span>非阶梯</span>
                          <p>{node.serviceType === 1 ? '单笔佣金的固定金额' : '单笔佣金的百分比'}, 每笔{node.serviceValue}{node.serviceType === 1 ? '元' : '%'}</p>
                        </div>
                      }
                    </Col>
                    <Col span="12">
                      <span>大金额服务费：</span>
                      <span>单笔佣金≥3万，每笔{node.bigServicePoint}%</span>
                    </Col>
                  </Row>
                </div>
              ))
            }
            </Card>
          )) : <Empty />
        }
      </div>
    </PageHeader>
    
  )
}

export default CompanyHistoryConfig