import React,{useRef,useState,useEffect} from 'react'
import {
  Row,
  Col,
  Table,
  Select,
  Form,
  Modal,
  Input,
  Button,
  message,
  DatePicker,
  Typography,
  Space
 } from 'antd';
import qs from 'qs'
import {request} from '../../../../utils/fetch'
import moment from 'moment';
import store from '../../../../store/index'
const { Paragraph } = Typography;
const FormItem = Form.Item;
const { Option } = Select;
const { RangePicker } = DatePicker;
const itemStyle = {
     width:'360px',
     marginRight:'15px'
}
const itemStyle1 = {
     width: '100%',
     marginRight:'15px'
}
const labelStyleObj = {
    labelCol:{ span: 6},
    wrapperCol:{ span: 18}
 }

function NewBanRight(props) {
    let path = 'company:CompanyTicketApply'
    const [form] = Form.useForm();
    const [mbform] = Form.useForm();
    const initSearch = {}
    const [tableData,setTableData] = useState([])
    const [visible,setVisible] = useState(false)
    const [previewVisible,setPreviewVisible] = useState(false)
    const [textUrl, settextUrl] = useState('')
    const [companyCode,setCompanyCode] = useState('')
    const [TaxEnterpriseLis, setTaxEnterpriseLis] = useState([])
    const columns = [
        // {
        //     title: '财税企业',
        //     dataIndex: 'taxEnterpriseName',
        //     key: 'taxEnterpriseName',
        //     width: 150,
        //     align:'center',
        // },
         {
            title: '子账号企业',
            dataIndex: 'companyName',
            key: 'companyName',
            width: 150,
            align:'center',
        },{
            title: '统一社会信用代码',
            dataIndex: 'certificateNumber',
            key: 'certificateNumber',
            width: 150,
            align:'center',
        }, {
            title: '开户行名称',
            dataIndex: 'bankName',
            key: 'bankName',
            width: 150,
            align:'center',
        },{
            title: '银行账号',
            dataIndex: 'bankNumber',
            key: 'bankNumber',
            width: 150,
            align:'center',
        },  {
            title: '确权手机号',
            dataIndex: 'mblNo',
            key: 'mblNo',
            width: 150,
            align:'center',
        },  {
            title: '确权状态',
            dataIndex: 'mobileConfirmStatus',
            key: 'mobileConfirmStatus',
            width: 150,
            render: (mobileConfirmStatus, record) => {
                if(mobileConfirmStatus=='01'){
                    return '未确权'
                }else{
                    return '已确权'
                }
            },
            align:'center',
        }, 
        {
        title: '操作',
        key: 'action',
        width: 150,
        align:'center',
        fixed: 'right',
        render: (text, record) => (
            <Space size="middle">
                {store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&<a onClick={() => {setCompanyCode(record.companyCode); mbform.setFieldsValue({mblNo:''}); setVisible(true)} }>修改手机号</a>}
                {
                   store.getState().userReducer.permissions.indexOf(`${path}:list`)>-1&&(record.mblNo&&record.mobileConfirmStatus == '01')
                    ?<a onClick={() => methodsFn.getConfirmationUrl(record) }>确权</a>
                    :null
                }
            </Space>
        ),
        },
    ];
   
    //组件渲染函数
    const myComponent = {
        renderSearchForm: () => {
            return (
            <Form form={form} initialValues={initSearch}>
                <Row>
                    {/* <FormItem label="财税企业" name="taxEnterpriseCode" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部" allowClear>
                            {TaxEnterpriseLis.map((e)=><Option key={e.code} value={e.code}>{e.enterpriseName}</Option>)}
                        </Select>
                    </FormItem> */}
                    <FormItem label="确权状态" name="mobileConfirmStatus" style={itemStyle} {...labelStyleObj}>
                        <Select placeholder="全部" allowClear>
                            <Option value={'02'}>已确权</Option>
                            <Option value={'01'}>未确权</Option>
                        </Select>
                    </FormItem>
                </Row>
                <Row>
                <Col span={24} md={24} lg={24}>
                    <FormItem style={{ float: 'right', whiteSpace: 'nowrap' }} >
                        <Button type="primary" onClick={methodsFn.handleSearch}>查询</Button>
                        <Button style={{ marginLeft: 10 }} onClick={methodsFn.handleFormReset}>重置</Button>
                    </FormItem>
                </Col>
                </Row>
            </Form>
            );
        },

    }
    //方法函数
    const methodsFn =  {
        async getTableList(obj){
            let result = await request.get('/company/companyBase/confirmationList',obj)
            console.log(result)
            setTableData(result)
        },
         // 获取所有财税企业
        async getTaxEnterpriseListall(company){
            const res = await request.get('/common/getTaxEnterpriseList',{})
            await setTaxEnterpriseLis(res)
        },
        getsearchObj(){
            let formObj = form.getFieldsValue();
            return formObj
        },
        handleSearch(){
            methodsFn.getTableList(methodsFn.getsearchObj())
        },
        handleFormReset(){
            form.resetFields();
        },
        async getConfirmationUrl(record){ 
            settextUrl('')
            let result = await request.post('/company/companyBase/getConfirmationUrl',{companyCode:record.companyCode})
            if(result){
                settextUrl(result)
                setPreviewVisible(true)
            }
        },
        async updatemblNo(){
            let formObj = {
                companyCode: companyCode,
                mblNo: mbform.getFieldsValue().mblNo
            }
            let result = await request.post('/company/companyBase/updatemblNo',formObj)
            if(result){
                message.success(result.msg)
                setVisible(false)
                methodsFn.getTableList(methodsFn.getsearchObj())
            }
           
        },
    }   
    useEffect(() => {
        methodsFn.getTableList(methodsFn.getsearchObj())
        methodsFn.getTaxEnterpriseListall(localStorage.getItem("company"))
    },[])
    return (
        <div>
            {myComponent.renderSearchForm()}
            <Table 
                scroll={{ x: 'max-content',y:500}}
                columns={columns} 
                rowKey={(record) => record.companyCode}
                dataSource={tableData} 
                pagination={false}
            />
             <Modal
                title='修改确权手机号 '
                visible={visible}
                // footer={false}
                onOk={() => methodsFn.updatemblNo()}
                onCancel={() =>{setVisible(false)} }>
                <div>
                <Form form={mbform}>
                    <Row>
                        <FormItem label="确权手机号" name="mblNo" style={itemStyle1} {...labelStyleObj} rules={[{ required: true }]}>
                            <Input placeholder="请输入确权手机号" />
                        </FormItem>
                    </Row>
                </Form>
                </div>
            </Modal>
            <Modal
                width={800}
                visible={previewVisible}
                title="确权链接"
                footer={null}
                onCancel={()=>{setPreviewVisible(false)}}
                >
                <Paragraph
                        ellipsis={{
                            rows: 2,
                        }}
                        copyable={{
                        text:textUrl,
                        tooltips: ['点击复制', '复制成功'],
                        }}
                    >{textUrl}</Paragraph>
            </Modal>
        </div>
    )
}

export default NewBanRight
