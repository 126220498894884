let baseURL; 
let imgUrl = '';
if(process.env.NODE_ENV === 'development'){
  // baseURL = 'http://rap2api.taobao.org/app/mock/274053';        //开发环境
  // baseURL = 'http://192.168.16.17:8600';    
  // baseURL = 'http://192.168.19.103:8700'; 
  baseURL = 'http://test.zchpay.com/wispay';    
}else{ 
  baseURL = '';         //生产环境
}


export {imgUrl, baseURL}